import Vue from 'vue'
import Router from 'vue-router'
import index from './components/index'
import job_detail from './components/job_detail'
import workList from './components/workList'
import register from './components/register'
import login from './components/login'
import publish from './components/publish'

Vue.use(Router)

export default new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes:[
        {
            path: '/', redirect: {name: 'index'}
        },{
            path: '/index', name: 'index', component: index
        },{
            path: '/detail/:id',name: 'detail',component:job_detail
        },{
            path: '/list',name: 'list',component:workList
        },{
            path: '/register',name: 'register',component:register
        },{
            path: '/login',name: 'login',component:login
        },{
            path: '/publish',name: 'publish',component:publish
        }
    ]
})
<template>
  <div>
    <div class="main">
      <div class="m_top">
        <div class="wrapper">
          <div class="position"></div>
          <div class="zwjbnr">
            <div class="left fl">
              <div class="zwm">
                <span> {{detail.bg_tit}}</span>
                <em> {{detail.xinzi}}</em>
                <div class="clear"></div>
              </div>
              <div class="jbyq">
                <span style="color: #333">
                  <img
                    src="/theme/gz_icp_body/img/dw_xq.png"
                    alt=""
                    class="dizhi"
                  />{{detail.address}}</span
                >
                <span>
                  <img src="/theme/gz_icp_body/img/gzjy.png" alt="" /> {{detail.jingyan}}
                </span>
                <span>
                  <img
                    src="/theme/gz_icp_body/img/xueli.png"
                    alt=""
                  />{{detail.xueli}}</span
                >
                <div class="clear"></div>
              </div>
              <div class="fuli">
                <ul>
                  <li class="jjText" v-for="(item,index) in detail.liangdian" v-bind:key="index">
                    <span class="jj">{{item}}</span>
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
              <div class="gsmc">
                <a href="javascript:;" title="广东中原地产代理有限公司"
                  ><span id="comName" class="gongsi"
                    >{{detail.gongsi}}</span
                  ></a
                >
                <div class="clear"></div>
              </div>
            </div>
            <div class="right fr">
              <div class="btn">
                <a
                  class="ljsq"
                  title="立即申请"
                  @click="apply"
                  >立即申请</a
                >
                <div class="clear"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="m-con">
        <div class="wrapper">
          <div class="mLeft fl">
            <div class="zwms">
              <div class="zwTit">
                <span>职位描述</span>
                <em></em>
              </div>
              <div class="zwmsCon">
                <div class="des" v-html="detail.zhiwei_desc">
                  
                </div>
                <div class="Job requirements"></div>
              </div>
            </div>
            <div class="lxfs">
              <div class="zwTit no">
                <span>联系方式</span>
                <em></em>
              </div>
              <div class="lxfsCon">
                <a href="javascript:void(0);" class="showBtn" @click="showPhone">{{phoneText}}</a>
                <div class="clear"></div>
              </div>

              <!-- <script>
                                $('.showBtn').click(function(){
                                    let phone = "150****6427";
                                    $(this).html(phone)
                                })

                            </script> -->

              <div class="lxts">
                <img
                  src="/theme/gz_icp_body/img/jg.png"
                  alt=""
                />注：联系我时，请说是在
                新余鸿鹄遨游信息技术有限公司上看到的。
              </div>
            </div>
            <div class="gzdd">
              <div class="zwTit no">
                <span>工作地点</span>
                <em></em>
              </div>
              <div class="gzddCon">
                <span class="dizhi">地址：  {{detail.address}}</span>
              </div>
            </div>
            <div class="zwts">
              <img
                src="/theme/gz_icp_body/img/jg.png"
                alt=""
              />以担保或任何理由索取财物，扣押证照，均涉嫌违法，请提高警惕
            </div>
          </div>
          <div class="mRight fl">
            <div class="gsxq" style="margin-top: 30px">
              <a href="javascript:;" title="广东中原地产代理有限公司">
                <div class="gsmc gongsi">
                  中原地产代理有限公司<img
                    src="../assets/image/sfrz_yrz.png"
                    alt=""
                  />
                </div>
              </a>
              <ul>
                <li>
                  <img src="../assets/image/xingzhi.png" alt="" />
                  <span> {{detail.company_area}}</span>
                  <div class="clear"></div>
                </li>
                <li>
                  <img src="../assets/image/renshu.png" alt="" />
                  <span> {{detail.guimo}}</span>
                  <div class="clear"></div>
                </li>

                <li>
                  <img src="../assets/image/dw_xq.png" alt="" />
                  <span class="dizhi"> {{detail.address}}</span>
                  <div class="clear"></div>
                </li>
              </ul>
            </div>
            <div class="xszw">
              <span class="xszwTit">相似职位</span>
              <div class="xszwCon">
                <ul id="zhiwei">
                  <li>
                    <a
                      href="/detail/54610"
                      title="钣金工（大工） "
                    >
                      <div class="zwm">
                        <span class="fl">钣金工（大工） </span>
                        <em class="fr"
                          >6000-12000<span class="font18">元/月</span></em
                        >
                        <div class="clear"></div>
                      </div>
                      <div class="jbyq">
                        <em class="vline"></em>
                        <span> 经验不限 </span>
                        <em class="vline"></em>
                        <span>学历不限</span>
                      </div>
                      <div class="gsm">
                        临沂市广泽汽车销售有限公司
                        <img src="../assets/image/sfrz_yrz.png" alt="" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/detail/72793"
                      title="双休包住外贸业务员国外展 "
                    >
                      <div class="zwm">
                        <span class="fl">双休包住外贸业务员国外展 </span>
                        <em class="fr"
                          >3500-5000<span class="font18">元/月</span></em
                        >
                        <div class="clear"></div>
                      </div>
                      <div class="jbyq">
                        <em class="vline"></em>
                        <span> 1-2年,可接收应届生 </span>
                        <em class="vline"></em>
                        <span>大专</span>
                      </div>
                      <div class="gsm">
                        合肥威士康医疗器械制造有限公司
                        <img src="../assets/image/sfrz_yrz.png" alt="" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/detail/1894"
                      title="招聘驾龄3年以上的司机"
                    >
                      <div class="zwm">
                        <span class="fl">招聘驾龄3年以上的司机</span>
                        <em class="fr"
                          >15000-20000<span class="font18">元/月</span></em
                        >
                        <div class="clear"></div>
                      </div>
                      <div class="jbyq">
                        <em class="vline"></em>
                        <span> 经验不限 </span>
                        <em class="vline"></em>
                        <span>学历不限</span>
                      </div>
                      <div class="gsm">
                        广州万来通汽车租赁有限公司
                        <img src="../assets/image/sfrz_yrz.png" alt="" />
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/detail/4173"
                      title="万达丨影视收银文员6千 "
                    >
                      <div class="zwm">
                        <span class="fl">万达丨影视收银文员6千 </span>
                        <em class="fr"
                          >5200-7500元/月</em
                        >
                        <div class="clear"></div>
                      </div>
                      <div class="jbyq">
                        <em class="vline"></em>
                        <span> 1-2年 </span>
                        <em class="vline"></em>
                        <span>中专</span>
                      </div>
                      <div class="gsm">
                        临沂市大海广告有限公司
                        <img src="../assets/image/sfrz_yrz.png" alt="" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>

    <div class="clear"></div>
  </div>
</template>
<script>
import data from '../assets/js/database.json'
import phone from '../assets/js/phone.json'
export default {
  data() {
    return {
      detail: [],
      phoneText: "点击查看"
    }
  },
  created() {
    this.detail = this.getDetail()
    console.log(this.detail)
    console.log(this.$route.params.id)
  },
  methods: {
    getDetail() {
      for(let i=0;i<data.length;i++){
        if(data[i].id == this.$route.params.id){
          return data[i]
        }
      }
      return ''
    },
    showPhone() {
      if(this.phoneText == '点击查看') {
        var item = phone[Math.floor(Math.random()*phone.length)];
        this.phoneText = item
      }
    },
    apply() {
      var loginStatus = localStorage.getItem('isLogin')
      if(!loginStatus){
        this.$router.push({ name: "login" })
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  width: 1000px;
  margin: 0 auto;
}
.layui-input::-webkit-input-placeholder,
.layui-select::-webkit-input-placeholder,
.layui-textarea::-webkit-input-placeholder {
  color: #999;
}

/*当前位置*/
.position {
  height: 48px;
  font-size: 12px;
  color: #999999;
  line-height: 48px;
}
.position a {
  color: #999999;
  margin: 0px 5px;
}
.position span {
  margin-left: 5px;
}
.position a:hover,
.position span {
  color: #ff7d3c;
}

.zwjbnr {
  padding: 4px 0px 30px;
}

.zwjbnr .left {
  width: 710px;
}

.zwjbnr .left .zwm span {
  display: block;
  float: left;
  font-size: 28px;
  color: #333333;
  margin-right: 30px;
  line-height: 37px;
}

.zwjbnr .left .zwm {
  margin-bottom: 16px;
}

.zwjbnr .left .zwm em {
  display: block;
  font-size: 20px;
  color: #ff7d3c;
  font-weight: bold;
  line-height: 37px;
}

.zwjbnr .left .jbyq {
  margin-bottom: 16px;
}

.zwjbnr .left .jbyq span {
  display: block;
  float: left;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-right: 20px;
}

.zwjbnr .left .jbyq span img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 6px;
}

.zwjbnr .left .fuli {
  margin-bottom: 16px;
}

.zwjbnr .left .fuli ul li {
  float: left;
  height: 16px;
  position: relative;
  margin-right: 10px;
}

.zwjbnr .left .fuli ul li .jj {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 13px;
}

.zwjbnr .left .fuli ul li .jj img {
  width: auto;
  height: auto;
  max-width: 16px;
  max-height: 16px;
  vertical-align: middle;
}

.zwjbnr .left .fuli ul li .ts {
  width: 60px;
  height: 36px;
  text-align: center;
  background: #fff;
  line-height: 36px;
  font-size: 12px;
  color: #666666;
  position: absolute;
  top: 18px;
  border: 1px solid #e8e8e8;
  z-index: 10;
  display: none;
}

.zwjbnr .left .fuli ul li:hover .ts {
  display: block;
}

.zwjbnr .left .gsmc span {
  display: block;
  float: left;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-right: 20px;
}

.zwjbnr .left .gsmc span img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 6px;
}

.zwjbnr .right {
  width: 290px;
  padding-top: 13px;
}

.zwjbnr .right .btn a {
  display: block;
  float: right;
  margin-left: 20px;
  text-align: center;
  font-size: 18px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.zwjbnr .right .btn .sczw {
  width: 118px;
  height: 40px;
  line-height: 40px;
  color: #ff7d3c;
  border: 1px solid #ff7d3c;
}

.zwjbnr .right .btn .sczw.hui {
  color: #999;
  border: 1px solid #ddd;
}

.zwjbnr .right .btn .ljsq,
.zwjbnr .right .btn .ysq {
  width: 120px;
  height: 42px;
  background: #ff9e3c;
  line-height: 42px;
  color: #fff;
}

.zwjbnr .right .btn .ysq {
  background: #ddd !important;
  color: #333 !important;
  display: none;
}

.zwjbnr .right .scfj {
  margin-top: 10px;
  text-align: right;
}

.zwjbnr .right .scfj .layui-btn {
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  color: #666666;
}

.zwjbnr .right .scfj .layui-btn img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 5px;
}

.m-con {
  background: #fff;
  margin-bottom: 10px;
}

.m-con .mLeft {
  width: 676px;
  padding-top: 13px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-right: 5px solid #f4f4f4;
}

.zwTit {
  height: 59px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.zwTit.no {
  border-bottom: none;
}

.zwTit > span,
.zwTit > .fl > span {
  display: block;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  line-height: 56px;
}

.zwTit em {
  display: block;
  width: 28px;
  height: 3px;
  background: #ff7d3c;
}

.zwTit a {
  display: block;
  font-size: 14px;
  color: #999999;
  line-height: 56px;
}

.zwms .zwmsCon {
  font-size: 14px;
  color: #333333;
  line-height: 30px;
  margin-bottom: 22px;
  white-space: pre-line;
}

.zwfl {
  margin-bottom: 12px;
}

.zwfl .zwflCon {
  font-size: 14px;
  color: #333333;
  line-height: 22px;
}

.lxfs {
  margin-bottom: 12px;
}

.lxfs .lxfsCon ul li {
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-right: 40px;
}

.lxfs .lxfsCon ul li img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 6px;
}

.gzdd .gzddCon span {
  display: block;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-bottom: 10px;
}

.gzdd .gzddCon #map {
  width: 656px;
  height: 199px;
  margin-bottom: 20px;
}

.zwts {
  font-size: 14px;
  color: #888888;
  line-height: 20px;
}

.zwts img,
.lxfs .lxts img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 16px;
}

.m-con .mRight {
  width: 279px;
  padding-left: 20px;
}

.mRight .zwfbr {
  padding: 30px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.mRight .zwfbr .img {
  width: 54px;
  height: 54px;
  text-align: center;
  line-height: 51px;
  margin-right: 13px;
}

.mRight .zwfbr .img img {
  width: auto;
  height: auto;
  max-width: 54px;
  max-height: 54px;
  vertical-align: middle;
}

.mRight .zwfbr .fbrmc > span {
  display: block;
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}

.mRight .zwfbr .fbrmc .xm span {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  line-height: 20px;
  margin-right: 9px;
}

.mRight .zwfbr .fbrmc .xm em {
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  padding: 0px 5px;
  line-height: 20px;
  background: #ffad84;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.mRight .gsxq {
  margin-bottom: 30px;
}

.mRight .gsxq .gsmc {
  line-height: 20px;
  font-size: 16px;
  color: #333333;
  margin-bottom: 16px;
}

.mRight .gsxq .gsmc img {
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 3px;
}

.mRight .gsxq ul li {
  margin-bottom: 16px;
}

.mRight .gsxq ul li img {
  float: left;
  margin-right: 5px;
  margin-top: 3px;
  vertical-align: middle;
}

.mRight .gsxq ul li span {
  display: block;
  float: left;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  max-width: 262px;
}

.mRight .xszw .xszwTit {
  display: block;
  width: 104px;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  background: #ff7d3c;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 20px;
}

.mRight .xszw ul li {
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
}

.mRight .xszw ul li .zwm {
  padding: 16px 16px 0px;
  margin-bottom: 10px;
}

.mRight .xszw ul li .zwm span {
  display: block;
  font-size: 14px;
  color: #ff7d3c;
  line-height: 20px;
  max-width: 112px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mRight .xszw ul li .zwm em {
  display: block;
  font-size: 18px;
  height: 20px;
  color: #ff7d3c;
  line-height: 20px;
  font-weight: bold;
  max-width: 112px;
  overflow: hidden;
}

.mRight .xszw ul li .jbyq {
  margin-bottom: 16px;
  padding: 0px 16px;
  height: 16px;
  overflow: hidden;
}

.mRight .xszw ul li .jbyq span {
  font-size: 12px;
  float: left;
  line-height: 16px;
  color: #9fa3b0 !important;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mRight .xszw ul li .gsm {
  height: 42px;
  padding: 0px 16px;
  line-height: 42px;
  font-size: 12px;
  color: #333;
  border-top: 1px solid #e8e8e8;
}

.mRight .xszw ul li .gsm img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 6px;
}

.m-bottom {
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.m-bottom .bottom > ul {
  width: 1011px;
}

.m-bottom .bottom > ul > li {
  width: 292px;
  height: 107px;
  float: left;
  padding: 18px 16px 0px;
  background: #fff;
  margin: 0px 11px 10px 0px;
  border: 1px solid #e8e8e8;
}

.m-bottom .bottom > ul > li .zwTop {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
}

.m-bottom .bottom > ul > li .zwTop .zwbt {
  font-size: 14px;
  color: #ff7d3c;
  display: block;
  line-height: 20px;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-bottom .bottom > ul > li .zwTop .price {
  font-size: 16px;
  color: #ff7d3c;
  font-weight: bold;
  line-height: 20px;
}

.m-bottom .bottom > ul > li .yq {
  margin-top: 10px;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
}

.m-bottom .bottom > ul > li .yq .cs {
  color: #333333;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-bottom .bottom > ul > li .yq span {
  display: block;
  float: left;
  margin-right: 16px;
  font-size: 12px;
  color: #666666;
}

.m-bottom .bottom > ul > li .fuli ul li {
  float: left;
  height: 16px;
  position: relative;
  margin-right: 10px;
}

.m-bottom .bottom > ul > li .fuli ul li .jj {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 13px;
}

.m-bottom .bottom > ul > li .fuli ul li .ts {
  width: 60px;
  height: 36px;
  text-align: center;
  background: #fff;
  line-height: 36px;
  font-size: 12px;
  color: #666666;
  position: absolute;
  top: 18px;
  border: 1px solid #e8e8e8;
  display: none;
}

.m-bottom .bottom > ul > li .fuli ul li:hover .ts {
  display: block;
}

.m-bottom .bottom > ul > li .zwBot .gsm {
  display: block;
  line-height: 44px;
  font-size: 12px;
  color: #333333;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-bottom .bottom > ul > li .zwBot .rz {
  width: 18px;
  height: 14px;
  text-align: center;
  line-height: 11px;
  margin-left: 6px;
  margin-top: 15px;
}

.m-bottom .bottom > ul > li .zwBot .rz img {
  width: auto;
  height: auto;
  max-width: 18px;
  max-height: 14px;
  vertical-align: middle;
}

.m-bottom .bottom > ul > li .zwBot .lxr {
  display: block;
  font-size: 12px;
  color: #383838;
  line-height: 44px;
}

.m-bottom .bottom > ul > li .zwBot .lxr img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 3px;
}

.lxfs .lxts {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin-top: 15px;
}

.jlList_box {
  display: none;
}

.jlList_con {
  padding: 20px 40px 40px;
}

.jlList_con .jlList_tit {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  line-height: 52px;
  border-bottom: 1px solid #ddd;
}

.jlList_con ul li {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  padding: 0px 10px;
  border-bottom: 1px dashed #ddd;
}

.jlList_con ul li span {
  float: right;
  display: none;
}

.jlList_con ul li em {
  display: block;
  float: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jlList_con ul li.now {
  color: #ddd;
}

.jlList_con ul li.now span {
  display: block;
}

.jlList_con ul li span img {
  vertical-align: middle;
  margin-bottom: 3px;
}

.jlList_con .tdBtn {
  text-align: center;
  width: 158px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin: 30px auto 0px;
  background: #ff7d3c;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.showBtn,
.showSq {
  display: inline-block;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff !important;
  background: #ff7d3c;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 5px 20px;
}

.jjText {
  height: auto !important;
}

.jjText span {
  width: auto !important;
  height: auto !important;
  border: 1px solid #999;
  padding: 3px 5px !important;
  margin-bottom: 5px;
  border-radius: 2px;
}

.listCon ul li {
  padding: 13px 20px 16px;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
  position: relative;
}
.listCon ul li .zwl {
  width: 540px;
}
.listCon ul li .zwl .zwmc span {
  display: block;
  font-size: 14px;
  height: 20px;
  color: #333333;
  line-height: 20px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listCon ul li .zwl .zwmc span:first-child {
  font-size: 16px;
  color: #f60;
  display: block;
  line-height: 20px;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listCon ul li .zwl .zwmc {
  margin-bottom: 10px;
}
.listCon ul li .zwl .zwmc em {
  display: block;
  font-size: 16px;
  color: #107ff5;
  font-weight: normal;
  line-height: 20px;
  margin-left: 16px;
}
.listCon ul li .zwl .zwyq {
  font-size: 12px;
  color: #9fa3b0;
  margin-top: 16px;
}
.listCon ul li .zwl .zwyq span {
  font-size: 12px;
  float: left;
  line-height: 17px;
  color: #9fa3b0 !important;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listCon ul li .zwfb em {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #999999;
  line-height: 16px;
  margin-bottom: 16px;
}
.listCon ul li .zwfb span {
  display: block;
  line-height: 18px;
  font-size: 12px;
  color: #383838;
  max-width: 74px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listCon ul li .zwfb span img {
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  margin-right: 10px;
}

.vline {
  display: inline-block;
  width: 1px;
  height: 12px;
  vertical-align: middle;
  background: #e0e0e0;
  margin: 2px 10px;
  float: left;
}

/*立即申请*/
.ljsq2 {
  width: 86px;
  position: absolute;
  display: none;
  top: 6px;
  right: 16px;
  margin-top: 17px;
}
.ljsq2 .shengqing {
  height: 36px;
  line-height: 36px;
  border: 1px #ff9e3c solid;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  background: #ff9e3c;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}

/*投递简历*/
.jlList_box2 {
  display: none;
}

.jlList_con2 {
  padding: 20px 40px 40px;
}

.jlList_con2 .jlList_tit {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  line-height: 52px;
  border-bottom: 1px solid #ddd;
}

.jlList_con2 ul li {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  padding: 0px 10px;
  border-bottom: 1px dashed #ddd;
}

.jlList_con2 ul li span {
  float: right;
  display: none;
}

.jlList_con2 ul li em {
  display: block;
  float: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jlList_con2 ul li.now {
  color: #ddd;
}

.jlList_con2 ul li.now span {
  display: block;
}

.jlList_con2 ul li span img {
  vertical-align: middle;
  margin-bottom: 3px;
}

.jlList_con2 .tdBtn2 {
  text-align: center;
  width: 158px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin: 30px auto 0px;
  background: #ff9e3c;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

/*提示*/
.body-bg {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  background: #000 \9;
  filter: alpha(opacity=50);
  top: 0px;
}
.zhiwei_zwjx {
  width: 454px;
  height: 256px;
  background: #ffffff;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  margin-top: -140px;
  z-index: 1000;
  display: none;
  left: 50%;
  margin-left: -227px;
}
.zhiding-tip {
  overflow: hidden;
  width: 100%;
  line-height: 52px;
  border-bottom: 1px solid #e8e8e8;
}
.zhiding-tip-sp1 {
  font-size: 18px;
  color: #333333;
  margin-left: 30px;
  margin-right: 9px;
}
.zhiding_wenti {
  font-size: 20px;
  color: #333333;
  width: 87%;
  line-height: 40px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.zhiding-tip .icon_close {
  display: block;
  float: right;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
.zhiding-tip .icon_close img {
  width: auto;
  height: auto;
  max-width: 11px;
  max-height: 11px;
  vertical-align: middle;
}
.zhiding-btn {
  overflow: hidden;
  width: 100%;
}

.zhiding-btn > span {
  display: inline-block;
  float: right;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
}
.zhiding-btn .zhiding-btn-sp1 {
  background: #ff7d3c;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #ff7d3c;
}
.zhiding-btn .zhiding-btn-sp1 a {
  background: #ff7d3c;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #ff7d3c;
}

/*申请职位*/
.pos_operate {
  height: 54px;
  width: 100%;
  margin-top: 26px;
}
.pos_operate .applyJobBtn {
  width: 280px;
  margin-right: 10px;
  height: 54px;
  line-height: 54px;
  padding: 0;
  color: #fff;
  background: #f60;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;
  text-indent: 0;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pos_operate_list {
  display: inline-block;
  vertical-align: middle;
}
.pos_operate_list .pos_operate_item {
  text-align: center;
  display: inline-block;
  width: 64px;
  height: 42px;
  padding: 6px 0;
  margin-right: 10px;
  font-size: 12px;
  color: #666;
}
.pos_operate_list .pos_operate_item .icon {
  display: block;
  font-size: 22px;
  margin: 0 auto 2px;
  color: #f1a82a;
  font-family: iconfont !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  width: 64px;
  height: 33px;
  background: url(/images/1129135857.png) no-repeat;
  background-position: 13px;
}
/*申请职位*/
.app {
  display: none;
  /*position: fixed;
    z-index: 9999;
   background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    */
}
.box-info {
  width: 783px;
  background: #fff;
}
.box-header {
  width: 100%;
  background: #fff;
  position: relative;
}
.head-title {
  font-size: 20px;
  color: #333;
  padding: 28px 0 18px;
  line-height: 20px;
  border-bottom: 1px solid #f3f3f3;
  margin: 0 30px;
}
.head-title .page-title {
  font-weight: 700;
}
.head-title .ljstitle {
  font-size: 14px;
  color: #666;
}
.head-title .ljsqel {
  margin-left: 16px;
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ff552e;
  color: #ff552e;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.close-box {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: url(/CompanyCenter/Images/close.png) no-repeat;
}

.figure-tip {
  font-size: 14px;
  color: #999;
  margin-top: 14px;
  text-align: center;
}
.upload {
  cursor: pointer;
}
.jbzlCon {
  padding-top: 30px;
  padding-left: 78px;
  padding-right: 78px;
  padding-bottom: 56px;
}
.layui-form-label {
  width: 110px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #666 !important;
  text-align: left !important;
  padding: 9px 15px 9px 0px !important;
}
.layui-form-label em {
  font-size: 16px;
  color: #ff7d3c;
  margin-right: 5px;
}
.layui-input-block {
  width: 250px;
  margin-left: 126px !important;
  font-size: 14px;
}
.selectDiv {
  width: 112px !important;
  font-size: 14px;
}
.xingzhi {
  display: inline-block;
  float: left;
  width: 82px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  margin-right: 20px;
  border: 1px solid #d8d8d8;
}
.xingzhi.now {
  color: #f0514e;
  border: 1px solid #f0514e;
  background: url(/CompanyCenter/Images/sj_xz.png) no-repeat right bottom;
}
.submit {
  margin: 30px 0 0 0;
}
.submit-btn {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 340px;
  background-color: #ff552e;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  margin: 0 auto;
}
.dhgt p {
  font-size: 16px;
  text-align: center;
  width: 100%;
  line-height: 90px;
}
.dhgt_anzong {
  text-align: center;
}
.dhgt_andl {
  display: inline-block;
  width: 124px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  background: #f60;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
}
.dhgt .dhgt_anniu {
  display: inline-block;
  width: 124px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  background: #f60;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
}
.dhjlrb {
  text-align: center;
  width: 500px;
}
.dhjlrb .lxr_img {
  width: 51px;
  height: 24px;
  line-height: 21px;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
}
.dhjlrb .lxr_img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.dhjlrb .lxr_phone {
  width: 234px;
  height: 30px;
  line-height: 27px;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
}
.dhjlrb .lxr_phone img {
  width: 234px;
  height: 30px;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.sjhm {
  float: left;
  width: 250px !important;
}
.hqyzm {
  float: left;
  width: 90px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  color: #f60;
  cursor: pointer;
  border: 1px solid #f60;
  margin-left: 16px;
}
.hqyzm2 {
  float: left;
  width: 90px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  border: 1px solid #999999;
  margin-left: 16px;
}
.city-selector-container {
  line-height: 36px;
  position: relative;
  font-size: 14px;
}
.k-form-item_nav .layui-input-inline {
  position: relative;
  font-size: 14px;
  width: 480px !important;
}
.k-form-item_nav .layui-input-inline input {
  padding-right: 30px;
  border-color: #c8ccd5;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d8dce6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #262b33;
  font-size: inherit;
  height: 36px;
  line-height: 36px;
  outline: none;
  padding: 0 22px 0 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border 86ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 86ms cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zwmb5 {
  width: 100% !important;
}
.zwmb5 {
  float: left;
  width: 480px !important;
  height: 36px;
  line-height: 36px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #999999;
  border: 1px solid #d8d8d8;
  text-indent: 10px;
}
.k-input__suffix {
  position: absolute;
  right: 5px;
  top: 0;
  color: #d8dce6;
  height: 100%;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 36px;
  cursor: pointer;
}
.k-input__suffix-inner {
  pointer-events: all;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.k-input__icon {
  width: 14px;
  height: 6px;
  display: inline-block;
  background: url(/CompanyCenter/Images/jiantou.png) no-repeat;
}
.city-selector-content {
  position: absolute;
  left: 25px;
  top: 35px;
  width: 477px;
  height: 400px;
  border: 1px solid #ccc;
  z-index: 2 !important;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: 1px 1px 2px #ccc;
  box-shadow: 1px 1px 2px #ccc;
  display: none;
}
.city-selector-content__title {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
}
.city-selector-content__title li {
  margin-right: 60px;
  color: #666;
  cursor: pointer;
}
.is-pulled-left {
  float: left;
}
.city-selector-content__title--selected {
  position: relative;
  color: #409eff !important;
  border-bottom: 1px solid;
}
.city-selector-content__area-item {
  width: 70px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 5px;
}
.streetli {
  color: #409eff;
}

.position-search {
  width: 236px;
  height: 30px;
  font-weight: 400;
  position: relative;
}
.position-search .position-input input {
  display: block;
  position: relative;
  width: 236px;
  padding: 0 0px 0 12px;
  line-height: 36px !important;
  height: 36px !important;
  font-size: 12px;
  border: 1px solid #e1e6ea;
  vertical-align: middle;
  box-sizing: content-box;
  font-family: arial, verdana, helvetica, "PingFang SC", "HanHei SC",
    STHeitiSC-Light, Microsoft Yahei, sans-serif;
  color: #414a60;
  -webkit-box-sizing: content-box;
}
.position-search .position-input .icon-p-search {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 16px;
  height: 16px;
  background: url(/CompanyCenter/Images/icon-p-search.png) no-repeat;
  background-size: 100%;
  -webkit-background-size: 100%;
  cursor: pointer;
}
.searchAppend {
  display: none;
  z-index: 99;
  bottom: 31px;
  left: 0px;
  background: white;
  position: absolute;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.position-search .blank {
  line-height: 17px;
  font-size: 12px;
  color: #9fa3b0;
  text-align: center;
  background-color: #fff;
  display: none;
  width: 250px;
  z-index: 99;
  top: 30px;
  left: 0px;
  background: white;
  position: absolute;
  padding: 8px 0px;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.searchAppend .searchList {
  text-indent: 10px;
  padding: 10px 24px 9px 24px;
  background-color: #fff;
  cursor: pointer;
}
.searchAppend .searchList p {
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
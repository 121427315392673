<template>
  <div>
    <div class="main">
      <div class="sousuo_z">
        <div class="ss_nav">
          <div class="ss_abou">
            <div class="tabCon">
              <input
                name="keyinp"
                id="index_search"
                maxlength="25"
                value=""
                placeholder="请输入搜索内容"
                class="search_input"
                type="text"
              />
              <input
                name="Submit"
                value="搜索"
                id="index_button"
                class="search_bnt_index"
                type="button"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="sydwzp">
        <div class="sydwzp_nav">
          <div class="syw fl">
            <div class="syw_top">
              <div class="syw_topz fl">顶置招聘</div>
              <div class="clear"></div>
            </div>
            <div class="dess_nav">
              <div class="syw_nav bjnavnot">
                <ul class="syw_navul" style="margin-right: 10px" id="dingzhi1">
                  <li v-for="(item,index) in topAdv" v-bind:key="index">
                    <div class="li_nav">
                      <router-link
                        v-bind:to="{name:'detail',params:{'id': item.id}}"
                        :title="item.bg_tit"
                      >
                        【{{item.xueli}}】{{item.bg_tit}}
                      </router-link>
                    </div>
                    <span>{{item.date}}</span>
                    <div class="clear"></div>
                  </li>
                </ul>
                <ul class="syw_navul syw_navul2" id="dingzhi2">
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/5076"
                        title="五险一金聘安保员"
                      >
                        【学历不限】五险一金聘安保员
                      </a>
                    </div>
                    <span>11-4</span>
                    <div class="clear"></div>
                  </li>
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/25281"
                        title="口腔门诊运营"
                      >
                        【大专】口腔门诊运营
                      </a>
                    </div>
                    <span>10-23</span>
                    <div class="clear"></div>
                  </li>
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/18637"
                        title="急聘配送员就近分配"
                      >
                        【学历不限】急聘配送员就近分配
                      </a>
                    </div>
                    <span>10-23</span>
                    <div class="clear"></div>
                  </li>
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/30510"
                        title="搬运工包吃住五险 "
                      >
                        【学历不限】搬运工包吃住五险
                      </a>
                    </div>
                    <span>10-19</span>
                    <div class="clear"></div>
                  </li>
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/9885"
                        title="化妆品直播合伙人"
                      >
                        【学历不限】化妆品直播合伙人
                      </a>
                    </div>
                    <span>10-31</span>
                    <div class="clear"></div>
                  </li>
                  <li>
                    <div class="li_nav">
                      <a
                        href="/detail/3344"
                        title="瑞幸咖啡门店店长 "
                      >
                        【高中】瑞幸咖啡门店店长
                      </a>
                    </div>
                    <span>10-18</span>
                    <div class="clear"></div>
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
            </div>
          </div>
          <div class="xyzph fl">
            <div>
              <div carousel-item="">
                <div>
                  <a href="javascript:void(0);">
                    <img src="../assets/image/indexAdv1.jpg" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div class="jyjt">
        <div class="jyjt_nav">
          <div class="jyjt_navzb fl" style="height: 230px">
            <p class="zp500">
              企业
              <img src="../assets/image/mroengs.png" alt="" />
            </p>
            <p class="gxjy">【高薪】就业</p>
            <div class="lanxian"></div>
            <span>招聘岗位</span>
          </div>
          <div class="jyjt_navyb fl">
            <ul class="jyjt_navybul" style="top: 0">
              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="德邦快递" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/20190813162619_8281.png"
                        alt="德邦快递"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="德邦快递">德邦快递</a>
                  </p>
                  <p class="comezwmc fr">
                    <span class="qesd">交通·运输·物流</span>
                  </p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">13</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="曹操出行" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/NT6N262117_5206300.jpg"
                        alt="曹操出行"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="曹操出行">曹操出行</a>
                  </p>
                  <p class="comezwmc fr">
                    <span class="qesd">汽车·摩托车</span>
                  </p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">1</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a
                    href="javascript:;"
                    title="正泰电器股份有限公司"
                    class="jyjt_linav_a"
                  >
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/636901621592817205.gif"
                        alt="正泰电器股份有限公司"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="正泰电器股份有限公司"
                      >正泰电器股份有限公司</a
                    >
                  </p>
                  <p class="comezwmc fr">
                    <span class="qesd">机械制造·机电·重工</span>
                  </p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">1</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="红旗连锁" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/636901622828851220.gif"
                        alt="红旗连锁"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="红旗连锁">红旗连锁</a>
                  </p>
                  <p class="comezwmc fr"><span class="qesd">批发·零售</span></p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">1</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="顺丰" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/Z20LB40611_8911220.jpg"
                        alt="顺丰"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="顺丰">顺丰</a>
                  </p>
                  <p class="comezwmc fr"><span class="qesd">其他行业</span></p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">2</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="台积电" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/FB222X2915_3956300.jpg"
                        alt="台积电"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="台积电">台积电</a>
                  </p>
                  <p class="comezwmc fr">
                    <span class="qesd">电子技术·半导体·集成电路</span>
                  </p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">3</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a href="javascript:;" title="富士康" class="jyjt_linav_a">
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/636901101341462268.gif"
                        alt="富士康"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="富士康">富士康</a>
                  </p>
                  <p class="comezwmc fr">
                    <span class="qesd">计算机硬件·网络设备</span>
                  </p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">5</span>家</span
                    >
                  </a>
                </div>
              </li>

              <li class="">
                <div class="jyjt_linav">
                  <a
                    href="javascript:;"
                    title="耐克（NIKE）"
                    class="jyjt_linav_a"
                  >
                    <div class="jyjt_liimg">
                      <img
                        src="../assets/image/636901620374640357.gif"
                        alt="耐克（NIKE）"
                        border="0"
                      />
                    </div>
                  </a>
                  <p class="comegs fr">
                    <a href="javascript:;" title="耐克（NIKE）">耐克（NIKE）</a>
                  </p>
                  <p class="comezwmc fr"><span class="qesd">批发·零售</span></p>
                  <div class="clear"></div>
                </div>
                <div class="company_box">
                  <a href="javascript:;">
                    <span class="pull-right fr"
                      >旗下公司<span class="text-blue">1</span>家</span
                    >
                  </a>
                </div>
              </li>
            </ul>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <!-- 最新招聘 -->

      <div class="new_recruit">
        <div class="new_recruit_nav">
          <div class="new_recruit_top">
            <div class="new_recruit_topbt fl">
              <h3>最新招聘</h3>
              <div class="clear"></div>
            </div>
            <div class="new_recruit_topmove fr">
              <a href="/list" title="更多职位"
                >更多&gt;&gt;</a
              >
            </div>
            <div class="clear"></div>
          </div>
          <div class="new_recruit_xian"></div>
          <div class="new_recruit_content">
            <div class="new_recruit_img">
              <img src="../assets/image/quanzhi.png" alt="" />全职
              <img
                src="../assets/image/bigorgres.png"
                alt=""
                class="new_reimg"
              />
              <div class="entzu_xian"></div>
            </div>
            <div class="new_recruit_contentzu fl">
              <ul>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>生活 | 服务业</p>
                    <span>餐饮·家政·保洁·安保</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>餐饮</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=送餐员"
                          >送餐员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=厨师/厨师长"
                          >厨师/厨师长</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=后厨"
                          >后厨</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=传菜员"
                          >传菜员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=配菜/打荷"
                          >配菜/打荷</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=洗碗工"
                          >洗碗工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=面点师"
                          >面点师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=茶艺师"
                          >茶艺师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=迎宾/接待"
                          >迎宾/接待</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=大堂经理/领班"
                          >大堂经理/领班</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=餐饮管理"
                          >餐饮管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=学徒"
                          >学徒</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=杂工"
                          >杂工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=咖啡师"
                          >咖啡师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=餐饮&amp;type=预订员"
                          >预订员</a
                        >
                      </li>
                      <li>
                        <strong>家政保洁/安保</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=保洁"
                          >保洁</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=保姆"
                          >保姆</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=月嫂"
                          >月嫂</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=育婴师/保育员"
                          >育婴师/保育员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=洗衣工"
                          >洗衣工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=钟点工"
                          >钟点工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=保安"
                          >保安</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=护工"
                          >护工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=家政保洁/安保&amp;type=送水工"
                          >送水工</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>人力 | 行政 | 管理</p>
                    <span>人事·行政·后勤·高级管理</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>人事/行政/后勤</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=文员"
                          >文员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=前台/总机/接待"
                          >前台/总机/接待</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=人事专员/助理"
                          >人事专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=人事经理/主管"
                          >人事经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=人事总监"
                          >人事总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=行政专员/助理"
                          >行政专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=行政经理/主管"
                          >行政经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=行政总监"
                          >行政总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=经理助理/秘书"
                          >经理助理/秘书</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=人事/行政/后勤&amp;type=薪酬/绩效/员工关系"
                          >薪酬/绩效/员工关系</a
                        >
                      </li>
                      <li>
                        <strong>司机</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=商务司机"
                          >商务司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=客运司机"
                          >客运司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=货运司机"
                          >货运司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=出租车司机"
                          >出租车司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=班车司机"
                          >班车司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=特种车司机"
                          >特种车司机</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=司机&amp;type=驾校教练/陪练"
                          >驾校教练/陪练</a
                        >
                      </li>
                      <li>
                        <strong>高级管理</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=CEO/总裁/总经理"
                          >CEO/总裁/总经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=首席运营官COO"
                          >首席运营官COO</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=首席财务官CFO"
                          >首席财务官CFO</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=首席技术官CTO"
                          >首席技术官CTO</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=副总裁/副总经理"
                          >副总裁/副总经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=总裁助理/总经理助理"
                          >总裁助理/总经理助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=分公司经理"
                          >分公司经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=高级管理&amp;type=合伙人"
                          >合伙人</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>销售 | 客服 | 淘宝</p>
                    <span>销售·客服·贸易·采购</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>销售</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=销售代表"
                          >销售代表</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=销售助理"
                          >销售助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=销售经理/主管"
                          >销售经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=销售总监"
                          >销售总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=电话销售"
                          >电话销售</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=销售支持"
                          >销售支持</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=汽车销售"
                          >汽车销售</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=区域销售"
                          >区域销售</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=渠道专员"
                          >渠道专员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=渠道经理/总监"
                          >渠道经理/总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=客户经理/主管"
                          >客户经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=大客户经理"
                          >大客户经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=团购业务员/经理"
                          >团购业务员/经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=会籍顾问"
                          >会籍顾问</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=销售&amp;type=网络销售"
                          >网络销售</a
                        >
                      </li>
                      <li>
                        <strong>客服</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=客服专员/助理"
                          >客服专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=客服经理/主管"
                          >客服经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=客服总监"
                          >客服总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=售前/售后服务"
                          >售前/售后服务</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=电话客服"
                          >电话客服</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=客服&amp;type=客户关系管理"
                          >客户关系管理</a
                        >
                      </li>
                      <li>
                        <strong>贸易/采购</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=外贸专员/助理"
                          >外贸专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=外贸经理/主管"
                          >外贸经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=采购员"
                          >采购员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=采购助理"
                          >采购助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=采购经理/总监"
                          >采购经理/总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=报关员"
                          >报关员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=商务专员/经理"
                          >商务专员/经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=贸易/采购&amp;type=买手"
                          >买手</a
                        >
                      </li>
                      <li>
                        <strong>超市/百货/零售</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=店员/营业员"
                          >店员/营业员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=收银员"
                          >收银员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=促销/导购员"
                          >促销/导购员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=理货员/陈列员"
                          >理货员/陈列员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=防损员/内保"
                          >防损员/内保</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=店长/卖场经理"
                          >店长/卖场经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=招商经理/主管"
                          >招商经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=奢侈品业务"
                          >奢侈品业务</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=品类管理"
                          >品类管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=食品加工/处理"
                          >食品加工/处理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=超市/百货/零售&amp;type=督导"
                          >督导</a
                        >
                      </li>
                      <li>
                        <strong>淘宝职位</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=网店店长"
                          >网店店长</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=淘宝客服"
                          >淘宝客服</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=淘宝美工"
                          >淘宝美工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=店铺文案编辑"
                          >店铺文案编辑</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=店铺推广"
                          >店铺推广</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=淘宝职位&amp;type=活动策划"
                          >活动策划</a
                        >
                      </li>
                      <li>
                        <strong>房产中介</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=置业顾问"
                          >置业顾问</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产店长/经理"
                          >房产店长/经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产店员/助理"
                          >房产店员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产客服"
                          >房产客服</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产内勤"
                          >房产内勤</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产评估师"
                          >房产评估师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=房产开发/策划"
                          >房产开发/策划</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=房产中介&amp;type=其他房产职位"
                          >其他房产职位</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>酒店 | 酒店管理</p>
                    <span>酒店</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>酒店</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=楼层经理"
                          >楼层经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=洗衣房经理"
                          >洗衣房经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=公共区域经理"
                          >公共区域经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=客房服务员"
                          >客房服务员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=房务部总监"
                          >房务部总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=客房部经理"
                          >客房部经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=预订经理"
                          >预订经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=预订员"
                          >预订员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=总机经理"
                          >总机经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=总机员工"
                          >总机员工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=前厅部经理"
                          >前厅部经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=前台主管"
                          >前台主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=礼宾经理"
                          >礼宾经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=宾客关系主任"
                          >宾客关系主任</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=前厅部员工"
                          >前厅部员工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=大堂副理"
                          >大堂副理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=值班经理"
                          >值班经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=救生员"
                          >救生员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=总经理"
                          >总经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=总经理助理"
                          >总经理助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=酒店店长"
                          >酒店店长</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=酒店&amp;type=前台/接待"
                          >前台/接待</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>市场 | 媒介 | 广告 | 设计</p>
                    <span>市场营销·策划·设计·广告</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>市场/媒介/公关</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=市场专员/助理"
                          >市场专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=市场经理/总监"
                          >市场经理/总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=市场拓展"
                          >市场拓展</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=市场调研"
                          >市场调研</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=市场策划"
                          >市场策划</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=媒介专员/助理"
                          >媒介专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=媒介经理/主管"
                          >媒介经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=会务会展专员/经理"
                          >会务会展专员/经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=品牌专员/经理"
                          >品牌专员/经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=公关专员/助理"
                          >公关专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=公关经理/主管"
                          >公关经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=市场/媒介/公关&amp;type=企划经理/主管"
                          >企划经理/主管</a
                        >
                      </li>
                      <li>
                        <strong>广告/会展/咨询</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=广告文案"
                          >广告文案</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=创意指导/总监"
                          >创意指导/总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=广告设计/制作"
                          >广告设计/制作</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=广告创意"
                          >广告创意</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=媒介策划/管理"
                          >媒介策划/管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=会展策划/设计"
                          >会展策划/设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=婚礼策划师"
                          >婚礼策划师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=咨询顾问"
                          >咨询顾问</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=咨询经理/主管"
                          >咨询经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=客户主管/专员"
                          >客户主管/专员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=广告/会展/咨询&amp;type=企业策划"
                          >企业策划</a
                        >
                      </li>
                      <li>
                        <strong>美术/设计/创意</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=美编/美术设计"
                          >美编/美术设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=服装设计"
                          >服装设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=家具/家居用品设计"
                          >家具/家居用品设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=平面设计"
                          >平面设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=美术指导"
                          >美术指导</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=店面/陈列/展览设计"
                          >店面/陈列/展览设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=工艺/珠宝设计"
                          >工艺/珠宝设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=多媒体/动画设计"
                          >多媒体/动画设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=产品/包装设计"
                          >产品/包装设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=装修装潢设计"
                          >装修装潢设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=美术/设计/创意&amp;type=CAD设计/制图"
                          >CAD设计/制图</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <div class="new_recruit_ulz fl">
                    <p>生产 | 物流 | 质控 | 汽车</p>
                    <span>工厂生产管理·技工·轻工</span>
                  </div>
                  <div class="new_recruit_uly fr">
                    <img
                      src="../assets/image/jiantou.png"
                      alt="Alternate Text"
                    />
                  </div>
                  <div class="clear"></div>
                  <div class="new_retzuul" style="height: 431px; display: none">
                    <ul class="type_list">
                      <li>
                        <strong>普工/技工</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=普工"
                          >普工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=综合维修工"
                          >综合维修工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=制冷/水暖工"
                          >制冷/水暖工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=电工"
                          >电工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=木工"
                          >木工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=钳工"
                          >钳工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=切割/焊工"
                          >切割/焊工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=钣金工"
                          >钣金工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=油漆工"
                          >油漆工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=缝纫工"
                          >缝纫工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=锅炉工"
                          >锅炉工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=车工/铣工"
                          >车工/铣工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=铲车/叉车工"
                          >铲车/叉车工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=铸造/注塑/模具工"
                          >铸造/注塑/模具工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=电梯工"
                          >电梯工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=操作工"
                          >操作工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=包装工"
                          >包装工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=手机维修"
                          >手机维修</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=水泥工"
                          >水泥工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=钢筋工"
                          >钢筋工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=管道工"
                          >管道工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=瓦工"
                          >瓦工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=组装工"
                          >组装工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=样衣工"
                          >样衣工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=染工"
                          >染工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=压熨工"
                          >压熨工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=普工/技工&amp;type=印花工"
                          >印花工</a
                        >
                      </li>
                      <li>
                        <strong>生产管理/研发</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=质量管理"
                          >质量管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=总工程师/副总工程师"
                          >总工程师/副总工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=设备管理维护"
                          >设备管理维护</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=工艺设计"
                          >工艺设计</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=车间主任"
                          >车间主任</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=生产计划"
                          >生产计划</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=化验/检验"
                          >化验/检验</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=厂长/副厂长"
                          >厂长/副厂长</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=生产总监"
                          >生产总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=维修工程师"
                          >维修工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=工业工程师"
                          >工业工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=材料工程师"
                          >材料工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=技术工程师"
                          >技术工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=生产管理/研发&amp;type=生产主管/组长"
                          >生产主管/组长</a
                        >
                      </li>
                      <li>
                        <strong>物流/仓储</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=物流专员/助理"
                          >物流专员/助理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=物流经理/主管"
                          >物流经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=物流总监"
                          >物流总监</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=调度员"
                          >调度员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=快递员"
                          >快递员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=仓库管理员"
                          >仓库管理员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=仓库经理/主管"
                          >仓库经理/主管</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=装卸/搬运工"
                          >装卸/搬运工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=供应链管理"
                          >供应链管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=单证员"
                          >单证员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=物流/仓储&amp;type=国际货运"
                          >国际货运</a
                        >
                      </li>
                      <li>
                        <strong>服装/纺织/食品</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=服装设计师"
                          >服装设计师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=纺织品设计师"
                          >纺织品设计师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=服装打样/制版"
                          >服装打样/制版</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=生产管理"
                          >生产管理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=样衣工"
                          >样衣工</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=食品/饮料研发/检验"
                          >食品/饮料研发/检验</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=板房/底格出格师"
                          >板房/底格出格师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=电脑放码员"
                          >电脑放码员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=服装/纺织/食品&amp;type=纸样师/车板工"
                          >纸样师/车板工</a
                        >
                      </li>
                      <li>
                        <strong>质控/安防</strong
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=质量管理/测试经理"
                          >质量管理/测试经理</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=质量检验员/测试员"
                          >质量检验员/测试员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=测试工程师"
                          >测试工程师</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=安全消防"
                          >安全消防</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=认证工程师/审核员"
                          >认证工程师/审核员</a
                        ><a
                          href="/t/5fa4297745454/g/list?area=质控/安防&amp;type=安全管理"
                          >安全管理</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <div class="contentzu_more">
                <a href="/list">查看全部</a>
              </div>
            </div>

            <!-- <script>
                            $('.new_recruit_contentzu ul li').each(function(k,v){
                                $(this).mouseover(function(){
                                    $(this).children('.new_retzuul').css('display','block')
                                })
                                $(this).mouseout(function(){
                                    $(this).children('.new_retzuul').css('display','none')
                                })
                            })

                        </script> -->
            <div class="new_recruit_contentyo fl">
              <ul class="new_recrultwo">
                <li v-for="(item,index) in newAdv" v-bind:key='index'>
                  <div class="no_one">
                    <a :href="'/detail/'+item.id" class="fl"
                      >{{item.bg_tit}}
                    </a>
                    <span class="fr"
                      >{{item.xinzi}}</span
                    >
                    <div class="clear"></div>
                  </div>
                  <div class="no_two">
                    <div class="jbyq fl">
                      <span></span>
                      <em class="vline"></em><span> {{item.jingyan}} </span>
                      <em class="vline"></em><span> {{item.xueli}} </span>
                    </div>

                    <div class="clear"></div>
                  </div>
                  <div class="no_son">
                    <div class="no_sontext fl">
                      <a href="javascript:;">{{item.gongsi}}</a>
                    </div>
                    <div class="clear"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="clear"></div>
          </div>
        </div>
      </div>

      <!-- 今日急招 -->

      <div class="new_recruit">
        <div class="new_recruit_nav">
          <div class="new_recruit_top">
            <div class="new_recruit_topbt fl">
              <h3>今日急招</h3>
              <div class="clear"></div>
            </div>
            <div class="new_recruit_topmove fr">
              <a href="/list">更多&gt;&gt;</a>
            </div>
            <div class="clear"></div>
          </div>
          <div class="jzqy_zong">
            <div class="new_recruit_contentjzqy zxjmqylove">
              <ul id="jizhao_list">
                <li v-for="(item,index) in todayAdv" v-bind:key="index">
                  <a
                    :href="'/detail/'+item.id"
                    :title="item.gongsi"
                  >
                    <div class="jzqy_one">
                      <span>
                        <img
                          src="/theme/gz_icp_body/img/bigsmongs.png"
                          alt=""
                          border="0"
                      /></span>
                      <span class="jzqy_onespan"
                        >{{item.gongsi}}</span
                      >
                      <div class="clear"></div>
                    </div>

                    <div class="jzqy_soun">
                      <span class="jzqy_sounone fl">{{item.bg_tit}} </span>
                      <span class="jzqy_sountwo fr"
                        >{{item.xinzi}}</span
                      >
                      <div class="clear"></div>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 热门招聘 -->
      <div class="new_recruit">
        <div class="new_recruit_nav">
          <div class="new_recruit_top">
            <div class="new_recruit_topbt fl">
              <h3>热门招聘</h3>
              <div class="clear"></div>
            </div>
            <div class="new_recruit_topmove fr">
              <a href="/list" style="width: 84px"
                >更多&gt;&gt;</a
              >
            </div>

            <div class="clear"></div>
          </div>
          <div class="new_recruit_contentxyzp">
            <div class="new_contentxyzpul">
              <ul id="remen_list">
                <li v-for="(item,index) in hotAdv" v-bind:key="index" style="width: 365px">
                  <a
                    :href="'/detail/'+item.id"
                    :title="item.bg_tit"
                  >
                    <div class="new_contentxyzpulimg fl">
                      <img src="../assets/image/default.png" alt="" />
                    </div>
                  </a>
                  <div class="new_contentxyzpulyou fl">
                    <div class="new_contentxyzpulyoutxt">
                      <a
                        :href="'/detail/'+item.id"
                        :title="item.bg_tit"
                        >{{item.bg_tit}}</a
                      >
                    </div>
                    <div class="new_contentxyzpulyousj">
                      <div class="new_contensj fl">
                        {{item.xinzi}}
                      </div>
                      <div class="new_contenbm fr">
                        <a
                          :href="'/detail/'+item.id"
                          :title="item.bg_tit"
                          >点击查看</a
                        >
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="clear"></div>
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import data from '../assets/js/webData.json'
import dataAll from '../assets/js/database.json'
export default {
  data() {
    return {
      topAdv:{},
      newAdv:{}
    }
  },
  created() {
    this.topAdv = data.index.topAdvertise;
    this.newAdv = data.index.newAdvertise;
    this.todayAdv = this.getRandomArrayElements(dataAll,20);
    this.hotAdv = this.getRandomArrayElements(dataAll,15);
  },
  methods: {
    random(min,max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    getRandomArrayElements(data, num){
      let list = []
      let listIndex = []
      for(let i = 1;i<=num;i++){
        listIndex.push(this.random(0,dataAll.length))
      }
      for(let j = 0; j<num;j++){
        let item =  dataAll[listIndex[j]]
        list.push(item)
      }
      return list
    }
  }
}
</script>
<style scoped>
body{
  background: #f5f5f5;
}
.sousuo_z {
  background: #f77a23;
}
.sousuo_z .ss_nav {
  width: 1100px;
  height: 132px;
  margin: 0 auto;
  background: url(../assets/image/Bitmap2.png) center bottom no-repeat;
  background-size: 100%;
  position: relative;
}
.sousuo_z .ss_nav .ss_abou {
  position: absolute;
  right: 10px;
  top: 52px;
}
.sousuo_z .ss_nav .ss_abou .tabCon .search_input {
  width: 398px;
  height: 35px;
  line-height: 35px;
  background: #fbfbfb;
  padding-left: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.sousuo_z .ss_nav .ss_abou .tabCon .search_bnt_index {
  width: 94px;
  height: 35px;
  line-height: 33px;
  background: #f77a23;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  font-family: STSongti-SC-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.04px;
  text-align: center;
  margin-left: -4px;
  cursor: pointer;
}
.sydwzp .sydwzp_nav {
  width: 1100px;
  margin: 0 auto;
  margin-top: 10px;
  background: #ffffff;
  padding: 9px 0 16px;
}
.sydwzp .sydwzp_nav .syw {
  width: 529px;
  margin-right: 21px;
}
.sydwzp_nav .xyzph {
  width: 240px;
}
.clear {
  clear: both;
}
.clear {
  zoom: 1;
  clear: both;
  height: 0;
  font-size: 0;
}
.sydwzp .sydwzp_nav .syw .syw_top .syw_topz {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #060606;
  letter-spacing: 0;
  line-height: 22px;
  border-left: 4px solid #f60;
  padding-left: 5px;
}
.sydwzp .sydwzp_nav .syw .syw_nav {
  margin-top: 12px;
  margin-left: 10px;
}
.syw_navul {
  width: 254px;
  float: left;
}
.sydwzp .sydwzp_nav .syw .syw_nav ul li {
  margin-top: 12px;
  float: left;
  margin-right: 0;
}
.sydwzp .sydwzp_nav .syw .syw_nav ul li .li_nav {
  width: 220px;
}
.sydwzp .sydwzp_nav .syw .syw_nav ul li .li_nav {
  float: left;
  width: 223px;
  line-height: 17px;
  font-family: STSongti-SC-Regular;
  font-size: 13px;
  color: #6f6f6f;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sydwzp .sydwzp_nav .syw .syw_nav ul li span {
  float: left;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #e34e4e;
  letter-spacing: 0;
  line-height: 17px;
}

.sydwzp .sydwzp_nav {
  width: 1100px;
  margin: 0 auto;
  margin-top: 10px;
  background: #ffffff;
  padding: 9px 0 16px;
}

.sydwzp .sydwzp_nav .RPO {
  width: 288px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  margin-left: 12px;
  position: relative;
}

.abxian {
  position: absolute;
  border: 1px solid #fff;
  top: 36px;
  left: 1px;
  width: 64px;
}

.sydwzp .sydwzp_nav .RPO .RPO_navs li {
  float: left;
  text-align: center;
  background: #f8f8f8;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #5a5a5a;
  letter-spacing: 0;
  line-height: 36px;
  border-right: 0;
  position: relative;
  cursor: pointer;
  height: 36px;
}

.sydwzp .sydwzp_nav .RPO .RPO_navs .cur {
  border: 1px solid #f60;
  background: #f60;
  color: #fff;
  box-sizing: border-box;
}

.sydwzp .sydwzp_nav .RPO .RPO_navs .cur i {
  display: block;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong {
  border-bottom: 1px dashed #999999;
  padding-top: 8px;
  box-sizing: border-box;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 {
  padding-top: 21px;
  padding-bottom: 12px;
}

.sydwzp .sydwzp_nav .RPO .ulcur {
  border: 1px solid #fff;
  box-sizing: border-box;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li {
  margin-left: 10px;
  margin-right: 10px;
  line-height: 17px;
  height: 50px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #5e5e5e;
  letter-spacing: 0;
  border-bottom: 1px dashed #dcdcdc;
  box-sizing: border-box;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 .rlzyjy_li {
  margin-bottom: 8px;
  margin-right: 0;
  line-height: 17px;
  height: 17px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #5e5e5e;
  letter-spacing: 0;
  border-bottom: 0;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 .rlzyjy_li span a {
  color: #5e5e5e;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 .rlzyjy_li span {
  float: left;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 .rlzyjy_li span:first-child {
  width: 208px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong4 .rlzyjy_li span:nth-child(2) {
  float: right;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ed5959;
  letter-spacing: 0;
  margin-right: 12px;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li:nth-child(4) {
  border-bottom: 0;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongli {
  margin-top: 5px;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongliyou {
  margin-top: 5px;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongli p {
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  width: 170px;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongli p a {
  display: block;
  width: 170px;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongli .RPO_zongliconr {
  margin-top: 4px;
}

.sydwzp
  .sydwzp_nav
  .RPO
  .RPO_zong
  li
  .RPO_zongli
  .RPO_zongliconr
  .RPO_zongliconrimg {
  width: 20px;
  height: 20px;
  line-height: 17px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ddd;
}

.sydwzp
  .sydwzp_nav
  .RPO
  .RPO_zong
  li
  .RPO_zongli
  .RPO_zongliconr
  .RPO_zongliconrimg
  img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.sydwzp
  .sydwzp_nav
  .RPO
  .RPO_zong
  li
  .RPO_zongli
  .RPO_zongliconr
  .RPO_zongliconrnz {
  margin-top: 2px;
  margin-left: 6px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #acacac;
  letter-spacing: 0;
  width: 140px;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sydwzp
  .sydwzp_nav
  .RPO
  .RPO_zong
  li
  .RPO_zongli
  .RPO_zongliconr
  .RPO_zongliconrnz
  a {
  color: #acacac;
  width: 140px;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongliyou span {
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #f55e5e;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  display: block;
}

.sydwzp .sydwzp_nav .RPO .RPO_zong li .RPO_zongliyou p {
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #acacac;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  margin-top: 6px;
}

.sydwzp .sydwzp_nav .RPO .RPO_move {
  margin: 7px 10px 8px 0;
  background: #ffffff;
  border: 1px solid #f77a23;
  width: 60px;
  line-height: 19px;
  box-sizing: border-box;
}

.sydwzp .sydwzp_nav .RPO .RPO_move a {
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #f77a23;
  letter-spacing: 0;
  margin-left: 9px;
  display: inline-block;
}

.sydwzp .sydwzp_nav .syw {
  width: 529px;
  margin-right: 21px;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topy {
  margin-top: 5px;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topz {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #060606;
  letter-spacing: 0;
  line-height: 22px;
  border-left: 4px solid #f60;
  padding-left: 5px;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topz span {
  width: 56px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #f60;
  letter-spacing: 0;
  line-height: 22px;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topz img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topy ul li {
  float: left;
}

.sydwzp .sydwzp_nav .syw .syw_top .syw_topy ul li a {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #9d9c9c;
  letter-spacing: 0;
}

.unline {
  margin: 0 6px;
  width: 1px;
  height: 8px;
  margin-top: 5px;
  background: #d8d8d8;
}

.sydwzp .sydwzp_nav .syw .syw_nav {
  margin-top: 12px;
  margin-left: 10px;
}

.sydwzp .sydwzp_nav .syw .syw_nav ul li:nth-child(2n) {
  margin-right: 0px;
}

.sydwzp .sydwzp_nav .syw .syw_nav ul li .li_nav {
  float: left;
  width: 223px;
  line-height: 17px;
  font-family: STSongti-SC-Regular;
  font-size: 13px;
  color: #6f6f6f;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sydwzp .sydwzp_nav .syw .syw_nav ul li .li_nav {
  width: 220px;
}

.sydwzp .sydwzp_nav .syw .syw_nav ul li .li_nav a {
  color: #6f6f6f;
}

.sydwzp .sydwzp_nav .syw .syw_nav ul li span {
  float: left;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #e34e4e;
  letter-spacing: 0;
  line-height: 17px;
}

.sydwzp .sydwzp_nav .syw .dess {
  margin-top: 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul {
  margin-left: 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul li {
  float: left;
  width: 62px;
  height: 51px;
  background: url(/images/blackinsf.png) no-repeat;
  background-size: 62px 41px;
  background-position: 0 10px;
  margin-right: 24px;
  cursor: pointer;
}

.sydwzp .sydwzp_nav .syw .dess ul li:last-child {
  margin-right: 0;
}

.sydwzp .sydwzp_nav .syw .dess ul li .dess_bj {
  width: 20px;
  height: 22px;
  margin: 0 auto;
  padding-top: 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjnav .dess_bj1 {
  background: url(/images/beijing2.png) no-repeat;
  background-size: 20px 22px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj1 {
  background: url(/images/beijing1.png) no-repeat;
  background-size: 20px 22px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .shnav .dess_bj2 {
  background: url(/images/shanghai2.png) no-repeat;
  background-size: 20px 18px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj2 {
  background: url(/images/shanghai1.png) no-repeat;
  background-size: 20px 18px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .hznav .dess_bj3 {
  background: url(/images/hangzhou2.png) no-repeat;
  background-size: 8px 20px;
  background-position: 6px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj3 {
  background: url(/images/hangzhou1.png) no-repeat;
  background-size: 8px 20px;
  background-position: 6px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .whnav .dess_bj4 {
  background: url(/images/wuhan2.png) no-repeat;
  background-size: 19px 20px;
  background-position: 1px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj4 {
  background: url(/images/wuhan1.png) no-repeat;
  background-size: 19px 20px;
  background-position: 1px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .gznav .dess_bj5 {
  background: url(/images/guangzhou2.png) no-repeat;
  background-size: 8px 20px;
  background-position: 6px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj5 {
  background: url(/images/guangzhou1.png) no-repeat;
  background-size: 8px 20px;
  background-position: 6px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .sznav .dess_bj6 {
  background: url(/images/shenzhen2.png) no-repeat;
  background-size: 20px 20px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur .dess_bj6 {
  background: url(/images/shenzhen1.png) no-repeat;
  background-size: 20px 20px;
  background-position: 0px 12px;
}

.sydwzp .sydwzp_nav .syw .dess ul li p {
  text-align: center;
  line-height: 17px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur {
  background: url(/images/orongs.png) no-repeat;
  background-size: 62px 51px;
  background-position: 0px 0px;
}

.sydwzp .sydwzp_nav .syw .dess ul .bjcur p {
  color: #ffffff;
}

.jyjt .jyjt_nav {
  width: 1100px;
  margin: 0 auto;
  margin-top: 14px;
}

.jyjt .jyjt_nav .jyjt_navzb {
  width: 180px;
  background: #ffffff;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.jyjt .jyjt_nav .jyjt_navzb .zp500 {
  margin-top: 35px;
  margin-left: 17px;
  font-family: PingFangSC-Regular;
  font-size: 23px;
  color: #1b89db;
  line-height: 32px;
  letter-spacing: 0;
}

.jyjt .jyjt_nav .jyjt_navzb .zp500 img {
  width: 23px;
  height: 30px;
  vertical-align: middle;
  margin-top: -15px;
}

.jyjt .jyjt_nav .jyjt_navzb .gxjy {
  margin-top: 8px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1b89db;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 12px;
}

.jyjt .jyjt_nav .jyjt_navzb .lanxian {
  width: 55px;
  height: 2px;
  background: #1b89db;
  margin-left: 20px;
  margin-top: 13px;
}

.jyjt .jyjt_nav .jyjt_navzb span {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #696969;
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 13px;
  display: block;
}

.jyjt .jyjt_nav .jyjt_navzb .jyjt_move {
  margin-top: 30px;
  margin-left: 20px;
  background: #1b89db;
  height: 19px;
  line-height: 19px;
  width: 51px;
  padding-left: 9px;
}

.jyjt .jyjt_nav .jyjt_navzb .jyjt_move a {
  display: block;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
}

.jyjt .jyjt_nav .jyjt_navyb {
  width: 920px;
  height: 230px;
  overflow: hidden;
  position: relative;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul {
  width: 100%;
  position: absolute;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li {
  background: #ffffff;
  border: 1px solid #fff;
  margin-left: 4px;
  width: 226px;
  height: 113px;
  position: relative;
  float: left;
  margin-bottom: 4px;
  box-sizing: border-box;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul .ybucur {
  border: 1px solid red;
  box-sizing: border-box;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav {
  border-bottom: dashed 1px #ededed;
  padding-bottom: 12px;
  padding-top: 6px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav a {
  display: inline-block;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .jyjt_linav_a {
  float: left;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comegs {
  margin-top: 12px;
  line-height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #383838;
  letter-spacing: 0;
  padding-bottom: 6px;
  margin-right: 8px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comegs a {
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 132px;
  text-align: right;
  height: 20px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comezwmc {
  line-height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #898e9d;
  letter-spacing: 0;
  margin-right: 8px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .jyjt_liimg {
  width: 55px;
  height: 55px;
  line-height: 48px;
  text-align: center;
  margin-left: 6px;
  margin-top: 6px;
  border: 1px solid #ddd;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 10px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comezwmc span {
  float: left;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comezwmc .zwiero {
  max-width: 65px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .comezwmc .qesd {
  max-width: 130px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .jyjt_linav .jyjt_liimg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .company_box {
  color: #8d92a1;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  margin: 0 8px;
}

.jyjt .jyjt_nav .jyjt_navyb .jyjt_navybul li .company_box a {
  color: #8d92a1;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-family: arial, verdana, helvetica, "PingFang SC", "HanHei SC",
    STHeitiSC-Light, Microsoft Yahei, sans-serif;
}
.text-blue {
  color: #f60;
}

/*最新招聘*/
.new_recruit .new_recruit_nav {
  width: 1100px;
  margin: 0 auto;
  margin-top: 17px;
}

.new_recruit_nav .new_recruit_top .new_recruit_topbt {
  border-left: 4px solid #f60;
  padding-left: 5px;
}

.new_recruit_nav .new_recruit_top .new_recruit_topbt h3 {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #383838;
  letter-spacing: 0;
  float: left;
}

.new_recruit_nav .new_recruit_top .new_recruit_topbt i {
  float: left;
  background: #bbbbbb;
  width: 1px;
  height: 12px;
  margin: 8px 8px 0;
}

.new_recruit_nav .new_recruit_top .new_recruit_topbt span {
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  letter-spacing: 0;
  line-height: 17px;
  margin: 6px 8px 0;
}

.new_recruit_nav .new_recruit_topul {
  margin-top: 5px;
  margin-left: 98px;
}

.new_recruit_nav .new_recruit_topul ul {
  margin-right: 24px;
}

.new_recruit_nav .new_recruit_topul ul li {
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #aeb1bc;
  letter-spacing: 0;
}

.new_recruit_nav .new_recruit_topul a {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  line-height: 20px;
  display: inline-block;
  margin-right: 20px;
}

.new_recruit_nav .new_recruit_topul a:hover {
  color: #f60;
}

.new_recruit_nav .new_recruit_topul ul li a {
  margin-right: 0px;
  float: left;
}

.new_recruit_nav .new_recruit_topul ul li a:hover {
  color: #f60;
}

.new_recruit_nav .new_recruit_topmove {
  margin-top: 5px;
}

.new_recruit_nav .new_recruit_topmove a {
  width: 60px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  display: inline-block;
}

.new_recruit_nav .new_recruit_xian {
  width: 1100px;
  height: 3px;
  background: #f77a23;
  margin-top: 10px;
}

.new_recruit_nav .new_recruit_content .new_recruit_img {
  background: #f77a23;
  width: 82px;
  height: 27px;
  line-height: 27px;
  color: #fff;
  position: relative;
}

.new_recruit_nav .new_recruit_content .new_recruit_img img {
  margin-left: 12px;
  margin-right: 6px;
}

.new_recruit_nav .new_recruit_content .new_recruit_img .new_reimg {
  position: absolute;
  top: 0;
  bottom: 0;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentzu {
  width: 230px;
  background: #fff;
  padding-top: 27px;
  padding-bottom: 2px;
  margin-top: -27px;
  box-sizing: border-box;
}

.new_recruit_nav .new_recruit_content .new_recruit_img .entzu_xian {
  position: absolute;
  right: -104px;
  top: 0px;
  width: 3px;
  background: #fff;
  height: 49px;
  display: none;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentzu ul {
  position: relative;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentzu ul li {
  border-bottom: 1px solid #e3e3e3;
  padding-top: 12px;
  margin-right: -1px;
  padding-bottom: 6px;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_recruit_ulz {
  margin-left: 0px;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_recruit_uly {
  margin-right: 0px;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul {
  position: absolute;
  top: -26px;
  left: 213px;
  background: #fff;
  z-index: 99;
  width: 859px;
  height: 675px;
  display: none;
  padding: 0 14px;
  overflow: auto;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul
  ul {
  margin-top: 30px;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul
  ul
  li {
  border-right: 0 !important;
  border-bottom: none;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul
  strong
  a {
  color: #000;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul
  a {
  margin: 0 11px;
  display: inline-block;
  color: #999;
  line-height: 28px;
  font-size: 13px;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_retzuul
  a:hover {
  color: #f60;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_recruit_ulz
  p {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #181818;
  letter-spacing: 0.98px;
  line-height: 19px;
  margin-bottom: 6px;
  width: 180px;
  height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_recruit_ulz
  span {
  font-family: STSongti-SC-Regular;
  font-size: 13px;
  color: #999;
  letter-spacing: 0.84px;
  line-height: 17px;
  width: 180px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  ul
  li
  .new_recruit_uly
  img {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  margin-top: 12px;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentyo {
  width: 862px;
  padding-bottom: 8px;
  margin-top: -27px;
  margin-left: 8px;
}

.new_recruit_mave {
  border: 1px solid #ff6100;
  width: 180px;
  height: 31px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
  line-height: 29px;
  margin-top: 16px;
  margin-bottom: 5px;
}

.new_recruit_mave a {
  display: block;
  font-family: STSongti-SC-Regular;
  font-size: 14px;
  color: #ff6100;
  letter-spacing: 0;
}

.new_recruit_contentyo .new_recrultop li {
  width: 20%;
  border: 1px solid #e3e3e3;
  border-left: 0;
  border-bottom: 0;
  height: 122px;
  float: left;
  box-sizing: border-box;
}

.new_recruit_contentyo .new_recrultop li a {
  display: block;
}

.new_recruit_contentyo .new_recrultop li:nth-child(5) {
  border-right: 0;
}

.new_recruit_contentyo .new_recrultop li div {
  width: 60px;
  height: 60px;
  line-height: 54px;
  text-align: center;
  margin: 0 auto;
  margin-top: 6px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

.new_recruit_contentyo .new_recrultop li div img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.new_recruit_contentyo .new_recrultop li p {
  margin: 0 auto;
  margin-top: 9px;
  margin-bottom: 1px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #383838;
  letter-spacing: 0;
  line-height: 20px;
  width: 96%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit_contentyo .new_recrultop li p a {
  color: #383838;
}

.new_recruit_contentyo .new_recrultop li span {
  margin-bottom: 1px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  letter-spacing: 0;
  line-height: 17px;
  display: block;
}

.new_recruit_contentyo .new_recrultwo li {
  background: #ffffff;
  width: 427px;
  height: 102px;
  border-left: 0;
  box-sizing: border-box;
  margin-bottom: -1px;
  float: left;
  margin: 4px;
  margin-left: 0;
  margin-right: 8px;
  padding: 2px;
}

.new_recruit_contentyo .new_recrultwo li:nth-child(2n) {
  margin-right: 0;
}

.new_recruit_contentyo .new_recrultwo li .no_one {
  margin: 10px;
  margin-top: 6px;
}

.new_recruit_contentyo .new_recrultwo li .no_one a {
  display: block;
  line-height: 22px;
  max-width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #333;
  letter-spacing: 0;
}

.new_recruit_contentyo .new_recrultwo li .no_one a:hover {
  color: #f60;
}

.new_recruit_contentyo .new_recrultwo li .no_one span {
  display: block;
  line-height: 22px;
  max-width: 134px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #757b8d;
  letter-spacing: 0;
}

.new_recruit_contentyo .new_recrultwo li .no_one span:nth-child(2) {
  height: 22px;
  line-height: 22px;
  max-width: 123px;
  overflow: hidden;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #f60;
  letter-spacing: 0;
  display: block;
}

.new_recruit_contentyo .new_recrultwo li .no_two {
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #e3e3e3;
}

.new_recruit_contentyo .new_recrultwo li .no_two .jbyq {
  height: 16px;
  width: 200px;
  overflow: hidden;
}

.new_recruit_contentyo .new_recrultwo li .no_two .jbyq span {
  float: left;
  line-height: 16px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  letter-spacing: 0;
}

.new_recruit_contentyo .new_recrultwo li .no_two .jbtp {
  width: 76px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.new_recruit_contentyo .new_recrultwo li .no_son {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  padding-bottom: 6px;
}

.new_recruit_contentyo .new_recrultwo li .no_son .no_sonimg {
  width: 24px;
  height: 24px;
  line-height: 21px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;
  box-sizing: border-box;
}

.new_recruit_contentyo .new_recrultwo li .no_son .no_sonimg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.new_recruit_contentyo .no_sontext {
  margin-left: 12px;
  margin-top: 4px;
}

.new_recruit_contentyo .no_sontext a {
  float: left;
  line-height: 16px;
  max-width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #858585;
  letter-spacing: 0;
}

.new_recruit_contentyo .no_sontext a:hover {
  color: #f60;
}

.new_recruit_contentyo .no_sontext span {
  float: left;
  line-height: 16px;
  max-width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #858585;
  letter-spacing: 0;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentzu .contentzu_more {
  width: 230px;
  background: #fff;
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 13px;
  color: #ff6a00;
  cursor: pointer;
  margin-top: 4px;
}

.new_recruit_nav .new_recruit_content .new_recruit_contentzu .contentzu_more a {
  display: block;
  background: #fff;
  font-size: 13px;
  color: #ff6a00;
  cursor: pointer;
}

.new_recruit_nav
  .new_recruit_content
  .new_recruit_contentzu
  .contentzu_more:hover
  a {
  background: #ff6a00;
}

/*今日最新急招企业*/
.new_recruit .new_recruit_nav .new_recruit_contentjzqy {
  margin-top: 13px;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy ul li {
  float: left;
  background: #ffffff;
  width: 274px;
  margin-right: 1px;
  margin-bottom: 1px;
  position: relative;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy ul li .jzqy_one {
  margin-top: 12px;
  margin-left: 8px;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy ul li .jzqy_one span {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #333;
  letter-spacing: 0;
  line-height: 20px;
  float: left;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit
  .new_recruit_nav
  .new_recruit_contentjzqy
  ul
  li
  .jzqy_one
  span:first-child {
  margin-right: 4px;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy ul li .jzqy_two {
  margin: 0 16px;
  margin-top: 9px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px dashed #e3e3e3;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy ul li .jzqy_two span {
  float: left;
  line-height: 16px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  letter-spacing: 0;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .jzqy_soun {
  margin: 0 16px;
  margin-top: 6px;
  padding-bottom: 10px;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .jzqy_soun span {
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #a5a5a5;
  letter-spacing: 0;
  line-height: 17px;
  width: 132px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit
  .new_recruit_nav
  .new_recruit_contentjzqy
  .jzqy_soun
  span:nth-child(2) {
  width: 100px;
  text-align: right;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .new_recrupoab {
  position: absolute;
  background: #ffffff;
  border: 1px solid #fa6603;
  top: 32px;
  left: 8px;
  z-index: 99;
  width: 266px;
  display: none;
  padding-bottom: 5px;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .new_recrupoab p a {
  float: left;
  width: 144px;
  margin-right: 10px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .new_recrupoab p:hover {
  color: #f60;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .new_recrupoab p span {
  float: left;
  width: 88px;
  text-align: right;
}

.new_recruit
  .new_recruit_nav
  .new_recruit_contentjzqy
  .new_recrupoab
  .new_recrupoabtext {
  border-bottom: 1px dashed #fa6603;
  margin: 0 12px 8px;
  line-height: 36px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #676767;
  letter-spacing: 0;
}

.new_recruit .new_recruit_nav .new_recruit_contentjzqy .new_recrupoab p {
  margin: 0 12px 4px;
  line-height: 17px;
  height: 17px;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #434242;
  letter-spacing: 0;
}

.new_recruit
  .new_recruit_nav
  .new_recruit_contentjzqy
  ul
  li:hover
  .new_recrupoab {
  display: block;
}

.new_recruit
  .new_recruit_nav
  .new_recruit_contentjzqy
  ul
  li:hover
  .jzqy_one
  span:nth-child(2) {
  color: #f60;
  text-decoration: underline;
}

.new_recruit_contentxyzp {
  margin-top: 13px;
}

.new_recruit_contentxyzp .new_contentxyzptop .new_contentxyzptoptet {
  width: 366px;
  margin-right: 1px;
  border: 1px dashed #fff;
  box-sizing: border-box;
  background: #fff !important;
  border-bottom: 0;
}

.new_recruit_contentxyzp .new_contentxyzptop .new_contentxyzptoptet .new_bai {
  background: #4e88e2;
  margin: 8px;
  position: relative;
  height: 110px;
}

.swiper-slide:first-child .new_bai {
  background: #4e88e2 !important;
}

.swiper-slide:nth-child(2) .new_bai {
  background: #21a42c !important;
}

.swiper-slide:nth-child(3n) .new_bai {
  background: #ebbb46 !important;
}

.swiper-slide:nth-child(3n + 1) .new_bai {
  background: #4e88e2 !important;
}

.swiper-slide:nth-child(3n + 2) .new_bai {
  background: #21a42c !important;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_bai
  .new_contentxyzptopimg {
  width: 80px;
  height: 80px;
  line-height: 76px;
  text-align: center;
  position: absolute;
  top: 15px;
  left: 15px;
  background: #fff;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_contentxyzptopimg
  img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_contentxyzptopxiab {
  width: 230px;
  margin-left: 108px;
  padding-top: 7px;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_contentxyzptopxiab
  p {
  margin: 0 auto;
  padding-top: 8px;
  margin-bottom: 2px;
  width: 100%;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  letter-spacing: 0;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_contentxyzptopxiab
  p
  a {
  display: block;
  width: 100%;
  text-align: center;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

.new_recruit_contentxyzp
  .new_contentxyzptop
  .new_contentxyzptoptet
  .new_contentxyzptopxiab
  span {
  width: 100%;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-top: 8px;
  padding-left: 6px;
  text-align: left;
}

.new_more {
  width: 86px;
  line-height: 20px;
  text-align: center;
  color: #000;
  background: #fff;
  float: right;
  margin-top: 12px;
  font-size: 12px;
}

.new_recruit_contentxyzp .new_contentxyzpul ul li {
  float: left;
  background: #ffffff;
  margin-right: 1px;
  width: 366px;
  padding-bottom: 12px;
  margin-top: 1px;
}

.new_recruit_contentxyzp .new_contentxyzpul ul li .new_contentxyzpulimg {
  width: 73px;
  height: 66px;
  line-height: 61px;
  text-align: center;
  margin-top: 14px;
  margin-left: 10px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.new_recruit_contentxyzp .new_contentxyzpul ul li .new_contentxyzpulimg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.new_recruit_contentxyzp .new_contentxyzpul ul li:nth-child(3),
.new_recruit_contentxyzp .new_contentxyzpul ul li:nth-child(6),
.new_recruit_contentxyzp .new_contentxyzpul ul li:nth-child(9) {
  margin-right: 0px;
}

.new_recruit_contentxyzp .new_contentxyzpul ul li .new_contentxyzpulyou {
  margin-top: 17px;
  margin-left: 7px;
}

.new_recruit_contentxyzp
  .new_contentxyzpul
  ul
  li
  .new_contentxyzpulyou
  .new_contentxyzpulyoutxt {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #6d6d6d;
  letter-spacing: 0;
  width: 270px;
  height: 38px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.new_recruit_contentxyzp
  .new_contentxyzpul
  ul
  li
  .new_contentxyzpulyou
  .new_contentxyzpulyousj {
  margin-top: 5px;
}

.new_recruit_contentxyzp
  .new_contentxyzpul
  ul
  li
  .new_contentxyzpulyou
  .new_contentxyzpulyousj
  .new_contensj {
  line-height: 17px;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #6d6d6d;
  letter-spacing: 0;
}

.new_recruit_contentxyzp
  .new_contentxyzpul
  ul
  li
  .new_contentxyzpulyou
  .new_contentxyzpulyousj
  .new_contenbm {
  width: 80px;
  border: 1px solid #f60;
  height: 20px;
  box-sizing: border-box;
}

.new_recruit_contentxyzp
  .new_contentxyzpul
  ul
  li
  .new_contentxyzpulyou
  .new_contentxyzpulyousj
  .new_contenbm
  a {
  width: 80px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  color: #f60;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  display: block;
  letter-spacing: 0;
}
</style>
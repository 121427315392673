<template>
  <div>
    <div id="wrap" style="margin-top: 10px">
      <div class="mainbody">
        <div class="mian_select c_center">
          <div class="div_fengexian" style="height: 10px"></div>
          <div class="ms_middle">
            <div class="div_input">
              <input
                type="text"
                class="txt_search searchboxbody"
                id="txt_search"
                autocomplete="off"
                placeholder="请输入搜索内容"
              />
              <input id="thispagetype" type="hidden" value="8" />
              <input
                type="button"
                class="search_btn"
                id="searchbtn"
                value="搜索职位"
              />
              <div id="searchAppend"></div>
            </div>
          </div>
          <div class="div_fengexian" style="height: 10px"></div>

          <!-- <script>
                    $('#searchbtn').click(function(){
                        let keyword =  $('#txt_search').val();
                        if(keyword == ''){
                            layer.msg('请输入要搜索的关键字');
                            $('#txt_search').focus();
                            return false;
                        } 
                        if ($('#txt_search').val().indexOf('/') > -1) {
                            new_str = $('#txt_search').val().replace(new RegExp('/', 'g'), "_");
                        } else {
                            new_str = $('#txt_search').val();
                        }
    
                        window.location.href = "/t/5fa4297745454/g/list?type=" + new_str;
                        return false;
    
                    })
    
                    $("#txt_search").keydown(function(event){
                        if(event.keyCode ==13){
                            let keyword =  $('#txt_search').val();
                            if(keyword == ''){
                                layer.msg('请输入要搜索的关键字');
                                $('#txt_search').focus();
                                return false;
                            } 
                            if ($('#txt_search').val().indexOf('/') > -1) {
                                new_str = $('#txt_search').val().replace(new RegExp('/', 'g'), "_");
                            } else {
                                new_str = $('#txt_search').val();
                            }
    
                            window.location.href = "/t/5fa4297745454/g/list?type=" + new_str;
                            return false;
                        }
                    })
    
                </script> -->

          <div class="div_fengexian" style="height: 10px"></div>
          <div class="ms_middle">
            <div class="ms_left1">类别：</div>
            <div class="ms_right1">
              <div class="msr_job" id="type_list">
                <a href="/list" class="type_artive">不限</a
                ><a href="/list?area=餐饮&amp;type=服务员"
                  >餐饮</a
                ><a
                  href="/list?area=家政保洁/安保&amp;type=保洁"
                  >家政保洁/安保</a
                ><a
                  href="/list?area=人事/行政/后勤&amp;type=文员"
                  >人事/行政/后勤</a
                ><a href="/list?area=司机&amp;type=商务司机"
                  >司机</a
                ><a
                  href="/list?area=高级管理&amp;type=CEO/总裁/总经理"
                  >高级管理</a
                ><a href="/list?area=销售&amp;type=销售代表"
                  >销售</a
                ><a
                  href="/list?area=客服&amp;type=客服专员/助理"
                  >客服</a
                ><a
                  href="/list?area=贸易/采购&amp;type=外贸专员/助理"
                  >贸易/采购</a
                ><a
                  href="/list?area=超市/百货/零售&amp;type=店员/营业员"
                  >超市/百货/零售</a
                ><a
                  href="/list?area=淘宝职位&amp;type=网店店长"
                  >淘宝职位</a
                ><a
                  href="/list?area=房产中介&amp;type=置业顾问"
                  >房产中介</a
                ><a href="/list?area=酒店&amp;type=楼层经理"
                  >酒店</a
                ><a
                  href="/list?area=市场/媒介/公关&amp;type=市场专员/助理"
                  >市场/媒介/公关</a
                ><a
                  href="/list?area=广告/会展/咨询&amp;type=广告文案"
                  >广告/会展/咨询</a
                ><a
                  href="/list?area=美术/设计/创意&amp;type=美编/美术设计"
                  >美术/设计/创意</a
                ><a href="/list?area=普工/技工&amp;type=普工"
                  >普工/技工</a
                ><a
                  href="/list?area=生产管理/研发&amp;type=质量管理"
                  >生产管理/研发</a
                ><a
                  href="/list?area=物流/仓储&amp;type=物流专员/助理"
                  >物流/仓储</a
                ><a
                  href="/list?area=服装/纺织/食品&amp;type=服装设计师"
                  >服装/纺织/食品</a
                ><a
                  href="/list?area=质控/安防&amp;type=质量管理/测试经理"
                  >质控/安防</a
                ><a href="/list?area=手机&amp;type=苹果"
                  >手机</a
                >
              </div>
            </div>
          </div>
          <div class="ms_middle" style="height: 30px">
            <div class="ms_left1">期望职位：</div>
            <div class="ms_right1">
              <div class="msr_job" id="list1"></div>
            </div>
          </div>
          <div class="div_fengexian" style="height: 10px"></div>
        </div>

        <div class="mainCon" style="width: 1100px; margin: auto">
          <div class="zhandi" style="height: 51px; display: none"></div>
          <div class="zwList">
            <div class="zwLeft fl">
              <ul id="data_list">
                <li v-for="(item,index) in list" v-bind:key="index">
                  <router-link
                    v-bind:to="{name:'detail',params:{'id': item.id}}"
                    :title="item.bg_tit"
                  >
                    <div class="zwTop fl">
                      <div class="bt">
                        <span class="zwbt fl">{{item.bg_tit}}</span>
                        <span class="price"
                          >{{item.xinzi}}</span
                        >
                        <div class="clear"></div>
                      </div>
                      <div class="yq">
                        <em class="vline"></em>
                        <span> {{item.jingyan}} </span>
                        <em class="vline"></em>
                        <span>{{item.xueli}}</span>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </router-link>
                  <div class="com fl">
                    <a
                      class="comName"
                      :href="'/detail/'+item.id"
                      :title="item.bg_tit"
                      target="_blank"
                    >
                      <span class="gsm fl">{{item.gongsi}}</span>

                      <i class="rz fl">
                        <img src="/theme/gz_icp_body/img/sfrz_wrz.png" alt="" />
                      </i>

                      <div class="clear"></div>
                    </a>
                  </div>
                  <div class="ljsq fr" style="display: block">
                    <a :href="'/detail/'+item.id"
                      ><div class="shengqing">立即申请</div></a
                    >
                  </div>
                  <div class="clear jobunderline"></div>
                </li>
              </ul>
              <div class="pageNav" id="page">
                <span style="color: #ccc; border-color: #ccc">上一页</span>
                <span
                  style="
                    color: #fff;
                    border-color: #ff9e3c;
                    background: #ff9e3c;
                  "
                  >1</span
                >
                <a href="javascript:index.page(2);">下一页</a>
              </div>
            </div>

            <div class="zwRight fl">
              <div class="zwrppq" style="display: block">
                <h3 class="zwrppq_title">各大行业职位任你选</h3>

                <div class="header1"></div>
                <canvas id="canvas25" width="1920" height="30"></canvas>
              </div>
              <div class="zwrTop"></div>
              <div class="ggw">
                <a href="javascript:;" title="求职/招聘神器">
                  <img src="../assets/image/ggw.png" alt="" />
                </a>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from '../assets/js/database.json'
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getRandomArrayElements(data, 20)
    // console.log(this.list)
  },
  methods: {
    random(min,max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    getRandomArrayElements(data, num){
      let listIndex = []
      for(let i = 1;i<=num;i++){
        listIndex.push(this.random(0,data.length))
      }
      for(let j = 0; j<num;j++){
        let item =  data[listIndex[j]]
        this.list.push(item)
      }
    }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  font-size: 12px;
  font-family: "Microsoft YaHei";
}

#wrap {
  margin: 15px auto;
  width: 1100px;
  text-align: left;
}
.clear {
  clear: both;
}

.zgmbx_navbox {
  width: 1000px;
  height: 45px;
  background: #f8f8f8;
  margin-bottom: 5px;
}
* {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
div {
  display: block;
}
body {
  background: #fff;
  color: #333;
}
body {
  font: 12px/1.5 "Microsoft Yahei", Tahoma, Arial, Helvetica, sans-serif;
  word-break: break-all;
  word-wrap: break-word;
}
* {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
html {
  color: #333;
  background: #fff;
}
.zgmbx_nav {
  width: 1000px;
  height: 45px;
  background: url(/images/ico_sm.png) no-repeat -552px -317px;
  padding-left: 23px;
  line-height: 44px;
  color: #666;
  margin: 0 auto;
  font: 12px/45px "宋体";
}
div {
  display: block;
}
body {
  background: #fff;
  color: #333;
}
body {
  font: 12px/1.5 "Microsoft Yahei", Tahoma, Arial, Helvetica, sans-serif;
  word-break: break-all;
  word-wrap: break-word;
}
html {
  color: #333;
  background: #fff;
}

#titleName {
  text-align: center;
  padding: 5px;
  line-height: 34px;
}

#dataInfo {
  text-align: center;
  padding: 5px;
  color: #999;
}

#contentInfo {
  line-height: 28px;
  padding: 5px 0;
}
#contentInfo img {
  max-width: 100%;
}

.fenxiang {
  text-align: right;
  float: right;
  clear: both;
}

.nextNews {
  clear: both;
}

.nextNews p {
  padding: 2px 0;
}

.nextNews a {
  color: #5b5b5b;
}

.nextNews a:hover {
  color: #f60;
}

/*公众号*/
.jdzjerweima {
  padding: 10px 0;
}
.jdzjerweima .erweima {
  width: 258px;
  height: 258px;
  margin: 20px auto;
}
/*责任编辑*/
.bianji {
  font-size: 14px;
  margin: 10px;
  color: #999;
  text-align: right;
}

/*免责*/
.mianze {
  margin: 20px 0 30px;
}
.mianze h3 {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  /* background: #1e63b0; */
  background: RGB(245, 245, 245);
  color: #333;
  padding: 0 10px;
  border: 1px solid RGB(230, 230, 230);
}
.mianze p {
  line-height: 24px;
  text-indent: 2em;
  margin: 10px 0;
  color: #333;
  font-size: 13px;
}

.clear {
  clear: both;
}
.mian_body {
  width: 980px;
  border: 1px solid #e9e9e9;
  padding-bottom: 20px;
}

.mian_select {
  background: #fff;
  border-top: 2px solid #ff7d3c;
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.top_search {
  width: 1000px;
  margin: 10px auto;
}

.mian_select .ms_left {
  width: 200px;
  height: 50px;
  text-align: right;
  float: left;
  line-height: 50px;
  vertical-align: middle;
}

.mian_select .ms_right {
  width: 790px;
  height: 50px;
  text-align: left;
  float: right;
}

.mian_select .ms_left1 {
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  float: left;
  text-align: right;
  color: #fe7903;
  font-weight: bold;
}

.mian_select .ms_right1 {
  width: 990px;
  float: right;
  text-align: right;
}

.ms_top {
  height: 50px;
}

.ms_middle {
  clear: both;
}

.ms_down {
  clear: both;
  height: 30px;
  margin-bottom: 10px;
}

#search_box {
  border: 2px solid #fe7903;
  width: 550px;
  height: 36px;
  float: left;
  vertical-align: middle;
  line-height: 36px;
  padding: 1px 15px;
}

#btn_search {
  border: none;
  width: 112px;
  height: 42px;
  float: left;
  background-image: url(../assets/image/search_btn.png);
  background-position: -2px 0px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.ms_right a {
  display: inline-block;
  height: 36px;
  width: 120px;
  font-size: 14px;
  margin-top: 2px;
  line-height: 36px;
  color: #4d4d4d;
  margin-left: 10px;
  border: 1px solid #ddd;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
}
.ms_right a:hover {
  color: #fe7903;
}

.msr_area,
.msr_job {
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
}

.msr_area a,
.msr_job a {
  text-decoration: none;
  color: #5b5b5b;
  display: inline-block;
  margin-right: 10px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #fff;
}

.msr_area a:hover,
.msr_job a:hover {
  color: #f60;
  background: #fbf5eb;
  border: 1px solid #f60;
}

/*其他搜索条件*/
.sel-con {
  display: inline-block;
  width: 125px;
  float: left;
  height: 24px;
  line-height: 24px;
  border: 1px solid #e2e2e2;
  background: #fff;
  color: #999;
  padding: 0 12px;
  position: relative;
  zoom: 1;
  margin: 0 15px 10px 0;
  text-align: center;
}

.ms_right1 .xueli {
  margin-top: 3px;
}

.sel-con .sel-detail {
  display: none;
  position: absolute;
  z-index: 999;
  top: 24px;
  left: -1px;
  width: 100%;
  background: #fff;
  border: 1px solid #d1d3d6;
  border-top: 0;
}

.sel-detail ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sel-detail li {
  height: 25px;
  text-align: center;
  position: relative;
  zoom: 1;
  color: #585858;
  cursor: pointer;
}

.dlHover {
  color: #f60 !important;
}

/*职位排序*/
.jobOrderBy {
  padding: 15px;
  height: 20px;
  border-bottom: 1px solid #ddd;
  background: #f8f8f8;
}

.orderBy {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 310px;
  float: left;
}

.orderBy li {
  float: left;
  overflow: hidden;
}

.orderBy li a:hover {
  color: #d88a3f;
}

.updateTimeOrderBy {
  width: 77px;
  height: 25px;
}

.updateTimeOrderBy a {
  display: block;
  height: 25px;
  line-height: 25px;
  text-indent: 8px;
  text-decoration: none;
  color: #333;
  background: url(/main/images/bg04.png) 0 -200px no-repeat;
}

.updateTimeOrderBy a:hover {
  background-position: 0 -250px;
}

.salaryOrderBy {
  width: 76px;
  height: 25px;
}

.salaryOrderBy a {
  display: block;
  height: 25px;
  line-height: 25px;
  text-indent: 8px;
  text-decoration: none;
  color: #333;
  background: url(/main/images/bg04.png) -77px -200px no-repeat;
}

.salaryOrderBy a:hover {
  background-position: -77px -250px;
}

.workOrderBy {
  width: 76px;
  height: 25px;
}

.workOrderBy a {
  display: block;
  height: 25px;
  line-height: 25px;
  text-indent: 8px;
  text-decoration: none;
  color: #333;
  background: url(/main/images/bg04.png) -77px -200px no-repeat;
}

.workOrderBy a:hover {
  background-position: -77px -250px;
}

.eduOrderBy {
  width: 76px;
  height: 25px;
}

.eduOrderBy a {
  display: block;
  height: 25px;
  line-height: 25px;
  text-indent: 16px;
  text-decoration: none;
  color: #333;
  background: url(/main/images/bg04.png) -153px -200px no-repeat;
}

.eduOrderBy a:hover {
  background-position: -153px -250px;
}

/*每页显示的职位数量*/
.showNum {
  float: left;
  background-image: url("/main/images/bg04.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0 -100px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 17px;
  padding-right: 10px;
  /*position: relative;*/
  width: 127px;
  height: 25px;
  line-height: 25px;
}
.showNum a {
  color: #333;
  text-decoration: none;
  padding: 0 4px;
}
.showNum a:hover {
  color: #d88a3f;
}

/*满足条件的职位总数*/
.showTotalNum {
  width: 200px;
  height: 25px;
  line-height: 25px;
  text-align: right;
  float: right;
  color: #333;
}
.showTotalNum #jobNum {
  color: #26713a;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}

/*职位列表*/
.jobList {
  margin-bottom: 20px;
}
.jobSingle {
  padding: 15px 15px 0;
}
.jobSingle h3 {
  margin: 0;
  padding: 0;
  height: 30px;
  line-height: 30px;
  float: left;
}
.jobSingle h3 a {
  color: #15c;
  text-decoration: none;
}
.jobSingle h3 a:hover {
  color: #d88a3f;
}
.jobOperate {
  width: 200px;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: right;
}
.jobOperate a {
  display: inline-block;
  text-decoration: none;
}
.jobOperate a:hover {
  color: #d88a3f;
}
.jianjie {
  color: #5b5b5b;
  line-height: 22px;
}
.jianjie > span {
  padding-right: 10px;
}
.jianjie span a {
  color: #5b5b5b;
  text-decoration: none;
}
.jianjie span a:hover {
  color: #d88a3f;
}
.xiangqing {
  line-height: 22px;
  color: #999;
}

/*yxl新加*/
.mainCon .titlebf {
  position: fixed;
  top: 0;
  left: 0;
  width: 1100px;
  z-index: 999;
  display: none;
  margin: auto;
}

.mainCon .title {
  height: 49px;
  background: #fff;
  margin-bottom: 10px;
  border-top: 2px solid #ff9e3c;
}
.mainCon .title .tLeft ul li {
  float: left;
  width: 108px;
}
.mainCon .title .tLeft ul li a {
  display: block;
  font-size: 16px;
  color: #666666;
  text-align: center;
  line-height: 24px;
  margin-top: 13px;
  border-right: 1px solid #e8e8e8;
}
.mainCon .title .tLeft ul li.now a,
.mainCon .title .tLeft ul li:hover a {
  color: #ff9e3c;
  line-height: 49px;
  margin-top: 0px;
  border-right: none;
}

.mainCon .title .xszw {
  font-size: 14px;
  color: #999999;
  line-height: 51px;
  padding-right: 20px;
  position: relative;
}
.mainCon .title .xszw .chall {
  float: left;
  width: 80px;
  font-size: 12px;
  border-right: 1px solid #e8e8e8;
}

.mainCon .title .xszw .chall input {
  margin-left: 19px;
  width: 16px;
  height: 16px;
  float: left;
  margin-top: 18px;
  margin-right: 5px;
}
.mainCon .title .xszw .op {
  float: left;
}
.mainCon .title .xszw .op span {
  float: left;
  width: 123px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  cursor: pointer;
  margin: 11px 0 0 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
.mainCon .title .xszw .but_sq {
  color: #fff;
  background-color: #ff9e3c;
  border: 1px solid #ff9e3c;
}

.mainCon .title .but_sc {
  color: #ff6000;
  background-color: #fff2e3;
  border: 1px solid #ffb865;
}
.mainCon .title .but_sq i {
  background-position: -70px 0;
}
.mainCon .title .but_sc i {
  background-position: -70px -20px;
}

.mainCon .title .op i {
  float: left;
  width: 20px;
  height: 20px;
  margin: 3px 2px 0 25px;
}
.dicon {
  background: url(/main/images/list_icon_dw_20171226.png) no-repeat;
}
.mainCon .zwLeft {
  width: 830px;
  margin-right: 10px;
  background: #fff;
}
.mainCon .zwLeft > ul > li {
  background: #ffffff;
  padding: 25px 30px 10px 20px;
  height: 60px;
  position: relative;
}
.mainCon .zwLeft > ul > li input {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16px;
  height: 16px;
  border-color: #ff6000;
}
.mainCon .zwLeft > ul > li:hover {
  background: #f5f5f5;
}
.mainCon .zwLeft > ul > li .zwTop .zwbt:hover,
.mainCon .zwLeft > ul > li .com .comName .gsm:hover {
  color: #ff7d3c;
}
.mainCon .zwLeft > ul > li.vip {
  position: relative;
}
.mainCon .zwLeft > ul > li .zwTop {
  padding-bottom: 11px;
  margin-right: 30px;
  width: 370px;
  margin-left: 23px;
}
.mainCon .zwLeft > ul > li .zwTop .zwbt,
.mainCon .zwLeft > ul > li .zwTop .zwbt span {
  font-size: 16px;
  color: #f60;
  display: block;
  line-height: 20px;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwLeft > ul > li .zwTop .zwbt span {
  max-width: 165px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
}
.mainCon .zwLeft > ul > li .zwTop .zwbt img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 10px;
}
.mainCon .zwLeft > ul > li .zwTop .price {
  font-size: 16px;
  color: #107ff5;
  font-weight: normal;
  line-height: 20px;
  margin-left: 16px;
}
.mainCon .zwLeft > ul > li .zwTop .yq {
  margin-top: 10px;
  font-size: 12px;
  color: #9fa3b0;
  margin-bottom: 10px;
}
.mainCon .zwLeft > ul > li .zwTop .yq .cs {
  color: #9fa3b0;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwLeft > ul > li .zwTop .yq span {
  display: block;
  float: left;
}
.mainCon .zwLeft > ul > li .zwTop .fuli ul li,
.fuli .gd {
  float: left;
  height: 16px;
  position: relative;
  margin-right: 10px;
}
.mainCon .zwLeft > ul > li .zwTop .fuli ul li .jj,
.fuli .gd .jj {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 13px;
}
.mainCon .zwLeft > ul > li .zwTop .fuli ul li .jj img,
.fuli .gd .jj img {
  width: auto;
  height: auto;
  max-width: 16px;
  max-height: 16px;
  vertical-align: middle;
}
.mainCon .zwLeft > ul > li .zwTop .fuli ul li .ts {
  width: 60px;
  height: 36px;
  text-align: center;
  background: #fff;
  line-height: 36px;
  font-size: 12px;
  color: #666666;
  position: absolute;
  top: 18px;
  border: 1px solid #e8e8e8;
  display: none;
}
.mainCon .zwLeft > ul > li .zwTop .fuli ul li:hover .ts {
  display: block;
}
.mainCon .zwLeft > ul > li .com {
  width: 280px;
}
.mainCon .zwLeft > ul > li .com .comName .gsm {
  display: block;
  font-size: 16px;
  color: #333333;
  max-width: 250px;
  height: 20px;
  line-height: 20px;
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwLeft > ul > li .com .comName .rz {
  width: 18px;
  height: 14px;
  text-align: center;
  line-height: 11px;
  margin-top: 3px;
}
.mainCon .zwLeft > ul > li .com .comName .rz img {
  width: auto;
  height: auto;
  max-width: 18px;
  max-height: 14px;
  vertical-align: middle;
}
.mainCon .zwLeft > ul > li .com .comsm {
  margin-top: 10px;
}
.mainCon .zwLeft > ul > li .com .comsm span {
  display: block;
  float: left;
  font-size: 12px;
  color: #9fa3b0;
  line-height: 18px;
  max-width: 79px;
  height: 18px;
  overflow: hidden;
}
.mainCon .zwLeft > ul > li .com .comsm span img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 3px;
}
.mainCon .zwLeft > ul > li .com .adrs {
  font-size: 12px;
  color: #666666;
  height: 16px;
  line-height: 16px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwLeft > ul > li .com .adrs img {
  width: 11px;
  height: 14px;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 8px;
}
.mainCon .zwLeft > ul > li .lxr .fbsj {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #999;
  line-height: 16px;
  margin-bottom: 10px;
}
.mainCon .zwLeft > ul > li .lxr .lxrcon {
  font-size: 12px;
  color: #383838;
  line-height: 18px;
  max-width: 74px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwLeft > ul > li .lxr .lxrcon img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 10px;
}
.mainCon .zwRight {
  width: 260px;
}
.mainCon .zwRight .zwrTop ul li {
  padding: 0px 16px;
  margin-bottom: 10px;
  background: #fff;
}
.mainCon .zwRight .zwrTop ul li .gsm span {
  display: block;
  font-size: 16px;
  color: #333333;
  height: 45px;
  line-height: 45px;
  max-width: 205px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainCon .zwRight .zwrTop ul li .gsm .rz {
  width: 18px;
  height: 14px;
  text-align: center;
  line-height: 11px;
  margin-top: 15px;
}
.mainCon .zwRight .zwrTop ul li .gsm .rz img {
  width: auto;
  height: auto;
  max-width: 18px;
  max-height: 14px;
  vertical-align: middle;
}
.mainCon .zwRight .zwrTop ul li .zzgw {
  padding: 10px 0px 16px;
  border-top: 1px solid #e8e8e8;
  font-size: 12px;
  color: #9fa3b0;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
}
.mainCon .zwRight .zwrTop ul li .zzgw span {
  color: #ff7d3c;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  max-width: 114px;
  overflow: hidden;
  vertical-align: middle;
  margin-bottom: 3px;
}
.mainCon .zwRight .ggw a {
  display: block;
  margin-bottom: 10px;
}
.mainCon .zwRight .ggw a img {
  max-width: 260px;
}
.layui-layer-btn .layui-layer-btn0 {
  border-color: #ff7d3c !important;
  background-color: #ff7d3c !important;
}

.bt {
  height: 20px;
}

/*模糊搜索下拉样式*/
.searchBox .wp .searchAppend {
  display: none;
  z-index: 99;
  top: 104px;
  right: 266px;
  background: white;
  position: absolute;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.searchList {
  line-height: 30px;
  font-size: 14px;
  height: 30px;
  text-indent: 10px;
  cursor: pointer;
}
.searchList:hover {
  background: #efefef;
}

/*投递简历*/
.jlList_box {
  display: none;
}

.jlList_con {
  padding: 20px 40px 40px;
}

.jlList_con .jlList_tit {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  line-height: 52px;
  border-bottom: 1px solid #ddd;
}

.jlList_con ul li {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  padding: 0px 10px;
  border-bottom: 1px dashed #ddd;
}

.jlList_con ul li span {
  float: right;
  display: none;
}

.jlList_con ul li em {
  display: block;
  float: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jlList_con ul li.now {
  color: #ddd;
}

.jlList_con ul li.now span {
  display: block;
}

.jlList_con ul li span img {
  vertical-align: middle;
  margin-bottom: 3px;
}

.jlList_con .tdBtn {
  text-align: center;
  width: 158px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin: 30px auto 0px;
  background: #ff9e3c;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.vline {
  display: inline-block;
  width: 1px;
  height: 12px;
  vertical-align: middle;
  background: #e0e0e0;
  margin: 2px 10px;
  float: left;
}
.jobunderline {
  line-height: 2px;
  width: 100%;
  border-bottom: 1px #f2f2f5 solid;
}

/*立即申请*/
.ljsq {
  width: 74px;
  position: relative;
  display: none;
}
.ljsq .shengqing {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  padding: 0 0;
  box-sizing: content-box;
  height: 36px;
  line-height: 36px;
  border: 1px #ff9e3c solid;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  background: #ff9e3c;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}

/*各大行业职位任你选*/
.zwrppq {
  background-color: #ff9e3c;
  margin-bottom: 15px;
  padding-bottom: 24px;
  position: relative;
  display: none;
}

.mainCon .zwrppq .zwrppq_title {
  display: block;
  padding: 20px 0 24px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
  text-align: center;
}

.mainCon .zwrppq .dlCon {
  margin: 0 20px;
}
.mainCon .zwrppq .dlCon ul li {
  margin: 0 0 16px;
  background: #fff;
  z-index: 10;
  position: relative;
}
.mainCon .zwrppq .dlCon ul .phone1 span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 0px 6px;
  font-size: 14px;
  color: #333333;
  line-height: 42px;
  cursor: pointer;
}
.mainCon .zwrppq .dlCon ul .phone1 input {
  outline: none;
  padding-left: 82px;
  width: 60%;
  height: 40px;
  background: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
}
.mainCon .zwrppq .dlCon ul .phone1 span img {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 4px;
}
.mainCon .zwrppq .dlCon ul .yzm input {
  outline: none;
  width: 45%;
  height: 40px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #fff;
  outline: none;
  border: none;
}
.mainCon .zwrppq .dlCon ul .yzmbtn span {
  display: inline-block;
  box-sizing: content-box;
  color: #ff9e3c;
  letter-spacing: 1px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  font-size: 16px;
  height: 42px;
  line-height: 42px;
  border: none;
  cursor: pointer;
}
.mainCon .zwrppq .dlCon .text_tip {
  text-align: center;
  color: #fff;
  padding-bottom: 10px;
  margin: 10px 0 0;
}
.mainCon .zwrppq .dlCon .text_tip a {
  color: #fff;
}
.mainCon .zwrppq .dlCon .text_tip a:hover {
  color: #000;
}

/*动画*/

.header1 {
  background-color: #ff9e3c;
  min-height: 0px;
  color: #fff;
  text-align: center;
}
canvas {
  display: block;
  width: 260px;
  height: 30px;
  position: absolute;
  bottom: 0;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.zwsj {
  text-align: center;
  min-height: 300px;
}

.zwsj img {
  margin: 150px 0;
}

.maindiv {
  width: 1000px;
}

.logo {
  width: 164px;
  height: 40px;
  float: left;
}

.logo img {
  border: none;
}

.top {
  position: relative;
  height: 40px;
}

.top .middle {
  float: left;
  height: 40px;
  padding-left: 15px;
}

.topcontent {
  width: 998px;
  border: solid 1px #dee3e7;
}

.topcontent .condown {
  border-top: solid 1px #dee3e7;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.divtopcontent {
  width: 960px;
}

.div_input {
  height: 35px;
  float: left;
  margin-left: 115px;
}

.txt_search {
  border: 1px solid #ccc;
  width: 485px;
  height: 24px;
  line-height: 24px;
  padding-left: 5px;
  background: #f2f2f2;
  height: 28px;
  float: left;
  line-height: 34px !important;
  padding-left: 10px;
  background: #f4f4f4;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-right: none;
  -moz-border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}

.search_btn {
  background-image: url("../assets/image/search_btn.png");
  width: 123px;
  height: 29px;
  border: none;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.moreselect {
  height: 35px;
  float: left;
  line-height: 35px;
  vertical-align: middle;
  margin-left: 10px;
}

.moresearch {
  display: inline-block;
  padding-right: 10px;
  color: #00d;
  cursor: pointer;
  text-decoration: none;
  background-image: url("/images/moresearch.png");
  background-repeat: no-repeat;
}

.moredown {
  background-position: right 13px;
}

.moreup {
  background-position: right -11px;
}

.complex {
  height: 40px;
  clear: both;
  width: 960px;
  display: none;
}

.complex .item {
  margin-top: 5px;
  height: 30px;
  float: left;
  margin-right: 10px;
}

.areaandjob {
  clear: both;
  width: 960px;
}

.areaandjob .list {
  clear: both;
}

.areaandjob .list .left {
  float: left;
  width: 60px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  color: #333;
}

.areaandjob .list .right {
  width: 890px;
  float: right;
  line-height: 30px;
  vertical-align: middle;
}

.divdowncontent {
  width: 960px;
}

.divdowncontent .left {
  float: left;
  width: 60px;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  color: #333;
}

.divdowncontent .right {
  width: 890px;
  float: right;
  line-height: 35px;
  vertical-align: middle;
  color: #000;
}

.datalist {
  width: 998px;
  border: 1px solid #f1f1f1;
}

.datalist .head {
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  background: #f9f9f9;
  color: #585858;
}

.datalist .content {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  border-top: 1px solid #f1f1f1;
  color: #595959;
}

.datalist .content a {
  color: #00d;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.datalist .content a:hover {
  color: #f00;
  text-decoration: underline;
}

.datalist .head .item,
.datalist .content .item {
  float: left;
  margin-right: 5px;
  overflow: hidden;
  height: 30px;
}

.sendinfo {
  font-size: 14px;
  text-decoration: underline;
  color: #f00;
}

.otherinfo {
  clear: both;
  height: 30px;
  margin-bottom: 5px;
  line-height: 30px;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
}

.otherinfo span {
  color: red;
  margin-right: 20px;
}

.otherinfo span a {
  color: #00d;
  text-decoration: none;
  margin-left: 10px;
}

.otherinfo span a:hover {
  color: red;
  text-decoration: solid;
}

/* 脚步 */

.pageNav {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.pageNav a {
  font-family: "Microsoft YaHei", "SimSun", "Arial, Helvetica, sans-serif";
  display: inline-block;
  text-align: center;
  color: #414a60;
  margin: 2px;
  padding: 4px 15px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
}
.pageNav a:hover {
  color: #ec704a;
}
.pageNav span {
  font-family: "Microsoft YaHei", "SimSun", "Arial, Helvetica, sans-serif";
  display: inline-block;
  text-align: center;
  color: #ccc;
  margin: 2px;
  padding: 4px 15px;
  cursor: pointer;
  font-size: 14px;
}
</style>
<template>
  <div class="body">
    <div class="container-fluid content">
      <div class="row">
        <div id="content" class="col-sm-12 full">
          <div class="row">
            <div class="login-box">
              <div class="header">用户登录</div>
              <form
                class="form-horizontal login"
                id="loginbox"
              >
                <!-- <input type="hidden" name="type" value="1" /> -->
                <!-- <input
                  type="hidden"
                  name="referer"
                  value="http://hyhycd.cn/t/5fa4297745454/detail_job/324915"
                /> -->
                <fieldset class="col-sm-12">
                  <div class="form-group">
                    <div class="controls row">
                      <div class="input-group col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name="username"
                          placeholder="用户名"
                          v-model="username"
                          @blur="checkAccount"
                        />
                        <!-- <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span> -->
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="controls row">
                      <div class="input-group col-sm-12">
                        <input
                          type="password"
                          class="form-control"
                          name="paswrd"
                          placeholder="登录密码"
                          v-model="password"
                          @blur="checkPassword"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="confirm">
                    <input type="checkbox" name="remember" />
                    <label for="remember">记住账号</label>
                  </div>
                  <div class="row">
                    <button
                      class="btn btn-lg btn-primary col-xs-12"
                      @click="login"
                    >
                      登 录
                    </button>
                  </div>
                </fieldset>
              </form>
              <a class="pull-right" href="/register">注 册</a>
              <div class="clearfix"></div>
            </div>
          </div>
          <!--/row-->
        </div>
      </div>
      <!--/row-->
    </div>
    <input type="hidden" name="web_titles" value="登录" />
  </div>
</template>
<script>
export default {
  data(){
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    getCookie(str){
      let va = localStorage.getItem(str)
      return va
    },
    checkAccount() {
        if (this.username == "") {
            alert("请输入用户名");
            return false;
        }
        console.log(this.username)
        if (this.username.length < 4 || this.username.length > 12) {
            alert("请输入4-12位的英文或数字");
            return false;
        }
        if (!/^[A-Za-z0-9]+$/.test(this.username)) {
            alert("用户名只能是英文和或数字");
            return false;
        }
        return true; 
    },
    checkPassword() {
        if (this.password == "") {
            alert("请输入密码");
            return false;
        }
        if (this.password.length < 6 || this.password.length > 18) {
            alert("请输入6-18位的英文或数字");
            return false;
        }
        if (!/^[A-Za-z0-9]+$/.test(this.password)) {
            alert("密码只能是英文和或数字");
            return false;
        }
        return true; 
    },
    login() {
      if (this.checkAccount() && this.checkPassword()) {
            if(this.username!= this.getCookie("userName") && this.password!= this.getCookie("userPassword")){
                alert("用户名或密码错误");
                return false
            }else{
              localStorage.setItem('isLogin', true)
              this.$router.push('/index')
            }
        }
    }
  }

};
</script>
<style type="text/css" scoped src="../assets/css/bootstrap.min.css"></style>
<style scoped>
.body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: #e6e8ea;
  position: relative;
  direction: ltr;
  color: #4c4f53 !important;
}
.login-box,
.register-box {
  width: 400px;
  padding: 20px;
  margin: 110px auto;
  background: #fff;
}
.login-box .header,
.register-box .header {
  background: #414141;
  color: white;
  text-align: center;
  margin: -20px -20px 20px -20px;
  padding: 15px;
  text-transform: uppercase;
}
.login-box .text-center li {
  display: inline-block;
}
.login-box .text-center li a {
  width: 45px;
  height: 45px;
  text-decoration: none;
  padding-top: 13px;
  font-size: 23px;
}
.login-box .text-center li a:hover {
  color: #fff;
}
.login-box label,
.register-box label {
  margin: 10px 5px -10px 5px;
}
.login-box .confirm,
.register-box .confirm {
  position: relative;
  margin: 20px 0 10px -15px;
}
.login-box .confirm label,
.register-box .confirm label {
  position: absolute;
  top: -9px;
  left: 30px;
}
.login-box form.register .input-group-addon,
.register-box form.register .input-group-addon {
  width: 35%;
}
a:hover,
a a:focus {
  color: #167495;
}
body {
  background: #e6e8ea;
}
</style>
<template>
  <div id="app">
    <Header v-if="othersShow"></Header>
    <router-view></router-view>
    <Footer v-if="othersShow"></Footer>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  name: "App",
  data() {
    return {
      othersShow: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    let url = this.$route.path;
    console.log(url);
    if (url.indexOf("register") >= 0 || url.indexOf("login") >= 0) {
      this.othersShow = false;
    } else {
      this.othersShow = true;
    }
  },watch: {
	'$route' () {
    // 在组件实例创建前调用
    let url = this.$route.path;
    console.log(url);
    if (url.indexOf("register") >= 0 || url.indexOf("login") >= 0) {
      this.othersShow = false;
    } else {
      this.othersShow = true;
    }
	}

}
}
</script>

<style>
body,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
select,
blockquote,
img,
img,
div,
p {
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
body {
  font-size: 12px;
  color: #747474;
  background: #f5f5f5;
  font-family: "PingFangSC-Light,helvetica neue,hiragino sans gb,arial,microsoft yahei ui,microsoft yahei,simsun,sans-serif";
}
a:link,
a:visited {
  color: #747474;
  text-decoration: none;
}
ul,
ol,
li {
  list-style-type: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl {
  float: left;
}

.fr {
  float: right;
}
.clear {
  clear: both;
  height: 0;
  width: 0;
  zoom: 1;
  overflow: hidden;
}
/*基本样式*/
html {
  width: 100%;
  min-width: 1000px;
}

body {
  width: 100%;
  font-family: "Microsoft YaHei", "SimSun", "Arial, Helvetica, sans-serif";
  color: #444;
  font-size: 12px;
  text-align: left;
  background-color: #f2f2f2;
  overflow-x: hidden;
  position: relative;
}

ul li {
  list-style: none;
}

h2 {
  font-size: 12px;
}
i,
em {
  font-style: normal;
}
img {
  border: none;
}

a:link {
  color: #444;
  text-decoration: none;
}

a:visited {
  color: #444;
  text-decoration: none;
}

a:hover {
  color: #ff5a00;
  text-decoration: none;
}

a:active {
  color: #ff5a00;
  text-decoration: none;
}

.clear {
  clear: both;
}
.clear {
  zoom: 1;
  clear: both;
  height: 0;
  font-size: 0;
}
.clear {
  clear: both;
  height: 0;
  width: 0;
  zoom: 1;
  overflow: hidden;
}
</style>

<template>
  <div>
    <div class="dheader">
      <div class="dheader_top">
        <span style="color: #fff; line-height: 30px"
          >欢迎来到新余鸿鹄遨游信息技术有限公司！</span
        >
        <div class="revision_right fr">
          <li>
            <div id="top-citylists">
              <div class="loading"></div>
            </div>
          </li>
          <div class="fl topBar-r" id="islogin" v-show="!islogin">
            <div class="zhuceimg">
              <a
                href="/register"
                style="display: block; width: 100%; height: 100%"
              ></a>
            </div>
            <a href="/register" style="margin-right: 10px">注册</a>
            <div class="yongimg"></div>
            <a href="/login" title="个人登录">个人登录</a>
          </div>
          <div class="fl topBar-r"  v-show="islogin">
            <span>{{username}}</span> <span style="margin-left: 20px;cursor:pointer;" @click="logout">退出</span>
          </div>
        </div>
        <input type="hidden" value="" class="area" />
        <!-- <script>
             $.get('/t/5fa4297745454/getMemberState', function (data) {
                if (data.state == 200) {
                    let html = `欢迎回来，<div class="yongimg"></div>${data.info.username}
                    <a href="/t/5fa4297745454/logout" title="退出">退出</a>
                    `
                    $('#islogin').html(html);
                }
            });

               // 获取城市地区，分类信息模板专用
            var layout_city = {
                init: function () {
                    this.putHtml();
                    this.getDefaultCity();
                },
                putHtml: function () {
                    $('#top-citylists').html('<style>#top-citylists{float: left;margin-right: 20px;font-size: 12px;position: relative;}#top-citylists .top-default-city{height: 37px;line-height: 37px;padding: 0 10px;border-left: 1px solid #fbfbfb;border-right: 1px solid #fbfbfb;border-bottom: 1px solid #e7e7e7;background-color:#fff;}#top-citylists .top-default-city a i{font-size: 14px;margin-right: 3px;}#top-citylists .top-citylist-w{display: none;position: absolute;left: 0;top: 37px;background: #fff;border-left: 1px solid #ddd;border-right: 1px solid #ddd;border-bottom: 1px solid #ddd;z-index: 9999;}#top-citylists .top-citylist-w .top-citylist--inner{width: 200px;}#top-citylists .top-citylist-w .top-citylist--inner ul{margin: 0;padding: 5px 10px;}#top-citylists .top-citylist-w .top-citylist--inner ul li{float: left;display: block;margin: 10px;}#top-citylists:hover .top-default-city{background: #fff; border-left: 1px solid #ddd; border-right: 1px solid #ddd; border-bottom: 1px solid #fff;}#top-citylists:hover .top-citylist-w{display: block;}#top-citylists a:hover{color: #ff5c00;}</style><div class="top-default-city"><a href="javascript:;" data-val=""><i class="iconfont icon-dingwei"></i><img src="/static/images/loading-2.gif" height="12" alt=""></a></div><div class="top-citylist-w"><div class="top-citylist--inner"><ul class="clearfix"></ul></div></div>');
                },
                getDefaultCity: function () {
                    $.get('/t/5fa4297745454/getDefaultCity', function (data) {
                        console.log(data);
                        if ($('.area').val() != '') {
                            $('#top-citylists').hide();
                        } else {
                            $('#top-citylists .top-default-city').html('<a href="javascript:;" data-val="' + data.data.def_city + '"><i class="iconfont icon-dingwei"></i>' + data.data.def_city + '</a>');
                            var city_html = '';
                            $(data.data.all_city).each(function (k, v) {
                                city_html += '<li><a href="javascript:;" data-val="' + v + '">' + v + '</a></li>';
                            });
                            $('#top-citylists .top-citylist-w .top-citylist--inner ul').html(city_html);
                        }

                    });
                   
                },
                setDefaultCity: function () {
                    $('body').on('click', '#top-citylists .top-citylist-w a', function () {
                        var city = $(this).attr('data-val');
                        $.post('/t/5fa4297745454/setDefaultCity', { default_city: city }, function (data) {
                            console.log(data);
                            if (data.state == 200) {
                                location.replace(location.href);
                            } else {
                                layer.msg(data.msg);
                            }
                        });
                    });
                },
                load: function () {
                    this.init();
                    this.setDefaultCity();
                }
            };

            $(function () {
                // layout_city.load();
            });

        </script> -->

        <div class="clear"></div>
      </div>
      <!--C端头部白色导航-->
      <div id="lg_tnav">
        <div class="inner">
          <div class="lg_tnav_l">
            <a href="/index" class="lg_logo"> 新余鸿鹄遨游信息技术有限公司 </a>
            <div class="site_f"></div>
            <div class="clear"></div>
          </div>
          <div class="lg_tnav_r">
            <ul class="lg_tnav_wrap">
              <li>
                <router-link to="/index">首页</router-link>
              </li>
              <li>
                <router-link to="/list">热招职位</router-link>
              </li>
            </ul>
            <div class="fbzwcjjl fr">
              <div class="fbzw_fl fl">
                <a href="/publish" title="发布职位" style="color: #f60"
                  >发布职位</a
                >
              </div>
              <div class="clear"></div>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import loginVue from './login.vue'
export default {
  data() {
    return {
      username: '',
      islogin: false
    }
  },
  created() {
    this.username = localStorage.getItem('userName')
    // if(this.username){
    this.islogin = localStorage.getItem('isLogin')
    // }
    console.log(this.islogin)
  },
  methods: {
    logout () {
      localStorage.removeItem('isLogin')
      // this.$router.push('/index')
      window.location.reload();
    }
  }
}
</script>
<style scoped>
#lg_tnav .lg_logo {
  width: auto;
  font-size: 20px;
  line-height: 20px;
  margin-right: 25px;
  margin: 22px 0 0;
  text-align: center;
  display: inline-block;
  padding-right: 50px;
  border-right: 1px solid #ccc;
}

#lg_tnav .inner {
  width: 1100px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
}

#lg_tnav .lg_tnav_wrap li {
  display: inline-block;
  margin-left: 30px;
  position: relative;
}
.clear {
  zoom: 1;
  clear: both;
  height: 0;
  font-size: 0;
}
#lg_tnav .lg_tnav_wrap li {
  display: inline-block;
  *float: left;
  margin-left: 30px;
  position: relative;
}
#lg_tnav .lg_tnav_wrap li.current a {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #ff6908;
  letter-spacing: 0.04px;
}
#lg_tnav .lg_tnav_wrap li a {
  font-family: PingFangSC-Medium;
  display: block;
  font-size: 16px;
  color: #707070;
  line-height: 60px;
  letter-spacing: 0.04px;
}
.fbzwcjjl {
  line-height: 60px;
}
.lg_tnav_l {
  flex-grow: 1;
}

#lg_tnav {
  background-color: #fff;
}
.fbzwcjjl .fbzw_fl,
.fbzwcjjl .cjjl_fl {
  width: 76px;
  height: 20px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #f60;
  color: #f60;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  line-height: 20px;
}
.zhuceimg {
  width: 18px;
  height: 18px;
  background-image: url(../assets/image/zhucetubiao.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
}
.yongimg {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url(../assets/image/sprite_361857f.png);
  background-position: -76px -174px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
}

/*头部1*/
.dheader {
  min-width: 1100px;
  background-color: #333;
}

.dheader .dheader_top {
  height: 30px;
  width: 1100px;
  margin: 0 auto;
}

.dheader .dheader_top .dheader_topz .lg_app {
  color: #f77a23;
  background: url(/img2016/app_icon_43ba565.png) 10px 0 no-repeat;
  padding: 0 12px 0 27px;
  line-height: 30px;
}

.dheader .dheader_top .dheader_topz .lg_os {
  border-left: 1px solid #5d5d5d;
  color: #c4c3c3;
  padding: 0 12px;
  margin: 7px 0 0;
}
/*头部1右边*/
.revision_right {
  color: #fff;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  float: right;
}
.revision_right .dlh {
  position: relative;
}

.revision_right .download a {
  display: inline-block;
  height: 18px;
  margin: 6px 0px;
  line-height: 18px;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
}
.revision_right .topBar-r {
  display: flex;
}

.revision_right .topBar-r a {
  padding: 0px 2px;
  position: relative;
  border-left: none;
  font-family: STSongti-SC-Regular;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
}

.revision_right .topBar-r .dlh span {
  height: 30px;
  font-size: 12px;
  color: #fff;
  padding-left: 10px;
  line-height: 30px;
  cursor: pointer;
}

.revision_right .topBar-r .dlh .dlhCon {
  position: absolute;
  width: 120px;
  height: auto;
  padding-top: 7px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: none;
  right: 0;
}

.dheader .dheader_top {
  height: 30px;
  width: 1100px;
  margin: 0 auto;
  text-align: left;
}
.lg_tnav_r {
  flex-grow: 2;
  display: flex;
  justify-content: space-between;
}
.lg_tnav_wrap li .router-link-active {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #ff6908 !important;
  letter-spacing: 0.04px;
}
</style>